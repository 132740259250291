import { Navigate,useLocation } from 'react-router-dom';

const PrivateRoutes = ({ children }) => {
    const auth = JSON.parse(localStorage.getItem("globalUser") || sessionStorage.getItem("globalUser"))
    const location = useLocation();

  if (!auth) {
    return <Navigate to="/login" />;
  }
  if (auth && location.pathname === '/') {
    return <Navigate to="/dashboard/app" />;
}

  return children;
};

export default PrivateRoutes;