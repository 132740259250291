import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getEmailTamplate: build.query({
      query: () => ({
        url: `${apiUrl.emailtamplate}`,
      }),
    }),
    getEmailVariableTamplate: build.query({
      query: () => ({
        url: `${apiUrl.emailVariables}`,
      }),
    }),
    getEmailTemplateById: build.query({
      query: (id) => ({
        url: `${apiUrl.emailtamplate}${id}`,
      }),
    }),
    updateEmailTemplate: build.mutation({
      query: (data) => ({
        url: `${apiUrl.emailtamplate}`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    addEmailTemplate: build.mutation({
      query: (data) => ({
        url: `${apiUrl.emailtamplate}`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    deleteEmailTemplate: build.mutation({
      query: (id) => ({
        url: `${apiUrl.emailtamplate}?id=${id}`,
        method: 'DELETE',
      }),
    }),
    commonCandidateMail: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidateMail}`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    getEmailSend: build.query({
      query: (id) => ({
        url: `${apiUrl.settings}sended_mail/${id}/`,
      }),
    }),
    getEmailReceived: build.query({
      query: (id) => ({
        url: `${apiUrl.settings}received_mail/${id}/`,
      }),
    }),
    getAllEmail: build.query({
      query: (id) => ({
        url: `${apiUrl.settings}all_mails/${id}/`,
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetEmailTamplateQuery,
  useGetEmailVariableTamplateQuery,
  useGetEmailTemplateByIdQuery,
  useUpdateEmailTemplateMutation,
  useAddEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
  useCommonCandidateMailMutation,
  useGetEmailSendQuery,
  useGetEmailReceivedQuery,
  useGetAllEmailQuery,
} = extendedApi;
