import { apiUrl } from "../utils/api";
import { apiBasePath } from "./services/BaseUrl";

let token = null;
const globalUser = JSON.parse(localStorage.getItem("globalUser"));
if (globalUser && globalUser.access) {
  token = globalUser.access;
} else {
  // Handle case where token is not available
  console.error("Access token not found in localStorage");
}
const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    dashStats: build.query({
      query: () => ({
        url: `${apiUrl.jobs}dashboard-stats/`,
        headers: {
          // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
          'Authorization': token ? `Bearer ${token}` : null,
        }
      }),
    }),    
  }),
  overrideExisting: false,
});

export const {
  useDashStatsQuery,
} = extendedApi;
