import React from 'react'
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    ListItemIcon,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogContent,
    Box,
} from '@mui/material';

function Preview() {
    return (
        <div className='ApplicationSteps'>

            Preview

        </div>
    )
}

export default Preview