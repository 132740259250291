import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query/react';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { 
  Card, 
  Box, 
  Stack, 
  Button, 
  TextField, 
  Container, 
  CircularProgress, 
  ListItem, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  Tabs, 
  Tab, 
  Avatar, 
  Divider, 
  Chip 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FileUpload from 'react-material-file-upload';
import { LoadingButton } from '@mui/lab';
// eslint-disable-next-line import/no-unresolved
import ImagePreview from 'src/components/imagePreview/ImagePreview';
// eslint-disable-next-line import/no-unresolved
import Modal from "src/components/modal/Modal";
// eslint-disable-next-line import/no-unresolved
import { useGetPerticularCompanyInfoQuery, useUpdateCompanyInfoMutation, useUpdateCompanyLogoMutation, useGetCompanyTagsQuery } from 'src/redux/services/settings/CareerSiteService';
// eslint-disable-next-line import/no-unresolved
import { useGetCountryQuery, useGetStateQuery, useGetCityQuery } from 'src/redux/services/settings/CountryStateCityService';
// eslint-disable-next-line import/no-unresolved
import { useGetTestimonialsQuery, useAddTestimonialMutation } from 'src/redux/services/settings/TestimonialService';
import MUIDataTable from 'mui-datatables';
import { showToast } from '../../../utils/toast';
import AddCompany from './AddCompany';



const CareerSiteUpdate = () => {
    const { companyId } = useParams();    
    const [tag, setTag] = useState('');
    const [files, setFiles] = React.useState([]);
    const { data,  refetch } = useGetPerticularCompanyInfoQuery(companyId);
    
    const { data: countryData } = useGetCountryQuery();
    const [countryId, setCountryId] = useState(skipToken);
    const [stateId, setStateId] = useState(skipToken);
    const [cityId, setCityId] = useState(skipToken);
    const { data: stateData } = useGetStateQuery(countryId !== undefined ? countryId : skipToken);
    const { data: cityData } = useGetCityQuery(stateId !== undefined ? stateId : skipToken);
    const { data: companyTagsData } = useGetCompanyTagsQuery();
    const [UpdateCompany, UpdateCompanyInfo] = useUpdateCompanyInfoMutation();
    const { data: testimonialData, refetch: testimonialDataRefetch } = useGetTestimonialsQuery()
    const [UpdateCompanyLogo, UpdateCompanyLogoInfo] = useUpdateCompanyLogoMutation();
    const [companyData, setCompanyData] = useState({
    company: "",
    domain: "",
    website: "",
    address: "",
    phone: "",
    email: "",
    pincode: "",
    landmark: "",
    country_id: "",
    state_id: "",
    city_id: "",
    logo: "",
    description: "",
    tag: [],
  })
    const [tabIndex, setTabIndex] = useState(0)
    const [tags, setTags] = useState(data?.companies?.tag)
    const [newTagValue, setNewTagValue] = useState("")
    const company = JSON.parse(localStorage.getItem("globalUser"))?.company?.id
    const [modalData, setModalData] = useState({ company })
    const [modalOpen, setModalOpen] = useState(false)
    const [addTestimonial, addTestimonialInfo] = useAddTestimonialMutation()
    const navigate = useNavigate()

    useEffect(() => {
        refetch();
        testimonialDataRefetch();
    }, [])
    
    useEffect(() => {
        if (data) {
        const response = data?.companies;
        setCompanyData({
            company: response.name,
            domain: response.domain,
            website: response.website,
            address: response.address,
            phone: response.phone,
            email: response.email,
            pincode: response.pincode,
            landmark: response.landmark,
            country_id: response.country_id,
            state_id: response.state_id,
            city_id: response.city_id,
            logo: response.logo,
            description: response.description,
            tag: response.tag
        });
        setCountryId(data?.companies?.country_id);
        setStateId(data?.companies?.state_id)
        setCityId(data?.companies?.city_id)
        }
    }, [data])

    useEffect(() => {
        if(addTestimonialInfo.isError) {
        showToast("error", "Error occurred while creating testimony")
        resetForm();
        setModalOpen(false)
        }
        if(addTestimonialInfo.isSuccess) {
        showToast("success", "Successfully created testimonials")
        resetForm();
        setModalOpen(false)
        }
    }, [addTestimonialInfo])

    if (UpdateCompanyInfo.isSuccess) {
        showToast("success", "career site updated successfully");
        refetch();
        UpdateCompanyInfo.reset();
        navigate('/dashboard/career-site');
    }
    if (UpdateCompanyInfo.isError) {
        showToast("error", UpdateCompanyInfo.error.data.msg);
    }
    if (UpdateCompanyLogoInfo.isSuccess) {
        showToast("success", UpdateCompanyLogoInfo.data.msg);
        refetch();
        UpdateCompanyLogoInfo.reset();
    }
    if (UpdateCompanyLogoInfo.isError) {
        showToast("error", "error while updating logo..");
        UpdateCompanyLogoInfo.reset();
    }

    const updateCareerSite = async () => {
        await UpdateCompany({ ...companyData, companyId });
    }

    const companyLogoChangeHandler = async (file) => {
        const formData = new FormData();
        formData.append('logo', file);
        // await UpdateCompanyLogo(formData);
    }

    const onInputChangeHandler = (e) => {
        setCompanyData({ ...companyData, [e.target.name]: e.target.name === "tag"?+e.target.value:e.target.value })
    }

    if (!data) {
        return (
            <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
              <CircularProgress />
            </Container>
        );
    }

    const handleTag = (event) => {
        setTag(event.target.value);
    };


    const handleChangeNewTagValue = (e) => setNewTagValue(e.target.value) 

    const handleAddTag = () => {
        setTags(prev => [...prev,newTagValue])
        setNewTagValue("")
        setCompanyData(prev => ({...prev, tag: tags}))
    }

    const handleDeleteTag = (index) => {
        setTags(prev => {
        const newTags = [...prev]
        newTags?.splice(index, 1)
        return newTags
        })
        setCompanyData(prev => ({...prev, tag: tags}))
    }
    
    const resetForm = () => setModalData({ company })

    const navigateCancel = () => {
        navigate(-1);
    }

    return (
        <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}
                >
                    <div className="backbutton tt-back" style={{display: "flex" }} >
                        <ArrowBackIosIcon onClick={navigateCancel} sx={{
                            cursor: "pointer"
                        }} />
                    </div>
                    {/* <h1 style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
                        Carrer Site
                    </h1> */}
                    <Typography 
                    variant="h3" 
                    color="textPrimary" 
                    sx={{ 
                        width: '300px',
                        textDecorationLine: 'underline',
                        backgroundColor: 'transparent !important'
                    }}
                    >
                    Update Carrer Site
                    </Typography>
                </Container>
        </Stack>
            <DialogContent>
                <Card sx={{ minWidth: 275, p: 4 }} variant="outlined">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} md={6}>
                            <TextField

                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="name"
                                value={companyData.company}
                                label="Company Name"
                                onChange={onInputChangeHandler}
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField

                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="domain"
                                value={companyData.domain}
                                label="Company domain"
                                onChange={onInputChangeHandler}
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField

                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="link"
                                value={companyData.website}
                                label="Website Link"
                                onChange={onInputChangeHandler}
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField

                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="address"
                                value={companyData.address}
                                label="Address"
                                onChange={onInputChangeHandler}
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField

                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="phone"
                                value={companyData.phone}
                                label="Phone"
                                onChange={onInputChangeHandler}
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField

                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="email"
                                value={companyData.email}
                                label="Email"
                                onChange={onInputChangeHandler}
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField

                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="pincode"
                                value={companyData.pincode}
                                label="Pincode"
                                onChange={onInputChangeHandler}
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField

                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="landmark"
                                value={companyData.landmark}
                                label="Landmark"
                                onChange={onInputChangeHandler}
                            />
                            </Grid>
                            <Grid item xs={4}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                <InputLabel id="select-country">Select country</InputLabel>
                                <Select
                                labelId="select-country"
                                id="country"
                                name="country"
                                  value={companyData.country_id}
                                onChange={onInputChangeHandler}
                                label="Select country"
                                >
                                {countryData && countryData?.countries?.map((country) => <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                <InputLabel id="select-state">State</InputLabel>
                                <Select
                                labelId="select-state"
                                id="state"
                                name="state"
                                  value={companyData.state_id}
                                onChange={onInputChangeHandler}
                                label="State"
                                >
                                {stateData ? stateData?.states?.map((state) => <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>) : <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>}
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                <InputLabel id="select-city">select City</InputLabel>
                                <Select
                                labelId="select-city"
                                id="city"
                                name="city"
                                  value={companyData.city_id}
                                onChange={onInputChangeHandler}
                                label="Select City"
                                >
                                {cityData ? cityData?.cities?.map((city) => <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>) : <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>}
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                            <Stack>
                                <ImagePreview
                                name='logo'
                                logo={companyData.logo}
                                onFileSelectSuccess={(file, e) => companyLogoChangeHandler(file)}
                                onFileSelectError={({ error }) => showToast("error", error)}
                                />
                            </Stack>
                            </Grid>
                            <Grid item xs={12} md={7}>
                            <TextField
                                multiline
                                rows={10}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={companyData.description}
                                label="About the Institute"
                                name="description"
                                onChange={onInputChangeHandler}
                            />
                            </Grid>
                            <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <LoadingButton variant="contained" onClick={updateCareerSite} loading={UpdateCompanyInfo.isLoading}> Update </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </DialogContent>
        </Container>
    );
    };

export default CareerSiteUpdate;
