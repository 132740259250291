import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
// import { countries } from "country-state-city";

import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Stack,
  Button,
  TextField,
  Container,
  CircularProgress,
  ListItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  TextareaAutosize,
  Checkbox,
  Typography,
  Box,
  Card,
  Backdrop
} from '@mui/material';
import { Select as AntSelect } from 'antd';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormik as useForm, Form, FormikProvider } from 'formik';
// eslint-disable-next-line import/no-unresolved
import { showToast } from 'src/utils/toast';
import { MultiSelect } from "react-multi-select-component";
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useAddCandidateMutation,
  useEditCandidateMutation,
  useGetCandidateByIdQuery,
  useAddCandidateWithResumeMutation,
  useAddParseResumeMutation,
} from '../../../redux/services/candidate/CandidateServices';
import {
  useGetCountryQuery,
  useGetStateQuery,
  useGetCityQuery,
} from '../../../redux/services/settings/CountryStateCityService';
import { useGetAssesmentQuery } from '../../../redux/services/main/AssesmentService';
import { useGetJobListQuery } from '../../../redux/services/jobs/JobListService';
import Back from '../../../assets/images/back.svg';
import FileUploadComponent from '../../../components/FileUploadComponent';
import { apiUrl } from '../../../utils/api';


function NewcreateCandidate() {
  const { editCandidateId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const candidate = useSelector((state) => state.candidate);
  const [AddCandidate, AddCandidateInfo] = useAddCandidateMutation();
  const { data: candidateData, refetch: getRefetch } = useGetCandidateByIdQuery(editCandidateId, { skip: editCandidateId === undefined });
  const [touchedFields, setTouchedFields] = useState({});
  const [value, setValue] = React.useState(dayjs('2000-08-18'));
  const [professionalStartDate, setProfessionalStartDate] = React.useState(null);
  const [professionalEndDate, setProfessionalEndDate] = React.useState(null);
  const [experienStartDate, setExperirnceStartDate] = React.useState(dayjs('2000-08-18'));
  const [experienEndDate, setExperirnceEndDate] = React.useState(dayjs('2000-08-18'));
  const [educationStartDate, setEducationStartDate] = React.useState(dayjs('2000-08-18'));
  const [educationEndDate, setEducationEndDate] = React.useState(dayjs('2000-08-18'));
  const [birthvalue, setbirthValue] = React.useState(null);
  const [admissionvalue, setadmissionValue] = React.useState(dayjs('2019-08-18'));
  const [graduationvalue, setgraduationValue] = React.useState(dayjs('2023-08-18'));
  const [showTextField, setShowTextField] = useState(false);
  const [experienceCount, setExperienceCount] = useState(1);
  const [experienceArray, setExperienceArray] = useState(candidateData?.user_experiences || [{ field1: '', field2: '', field3: '', field4: null, field5: null }]);
  const [educationCount, setEducationCount] = useState(1);
  const [educationArray, setEducationArray] = useState(candidateData?.user_educations || [{ field1: '', field2: '', field3: '', field4: null, field5: null }]);
  const subjectOptions = candidateData?.subjects?.map(subject => ({
    label: subject,
    value: subject
  }));
  const [selected, setSelected] = useState(subjectOptions || []);
  const [piplineStatus, setPiplineStatus] = useState('')
  const [professionalDegreeOptions, setProfessionalDegreeOptions] = useState([
    'B.Ed.',
    'D.Ed.',
    'Diploma',
    'B.E',
    'B.Arch',
    'MBBS',
    'Other',
  ]);
  const [educationalDegreeOptions, setEducationalDegreeOptions] = useState([
    'B.Ed.',
    'D.Ed.',
    'Diploma',
    'B.E',
    'B.Arch',
    'MBBS',
    'Other',
  ]);
  const [qualificationOptions, setQualificationOptions] = useState([
    'Secondary_School',
    'High_School',
    'Diploma',
    'Post_Graduate_Diploma',
    'Graduate',
    'Post_Graduate',
    'Doctorate',
  ]);
  const [educationBoardOptions, setEducationBoardOptions] = useState([
    'ICSE',
    'CBSE',
    'STATE_BOARD',
    'IGCSE',
    'IB',
    'OTHER',
    'NONE',
  ]);
  const [jobRoleOptions, setJobRoleOptions] = useState([
    'Teaching',
    'Administer',
    'Manager',
    'head_of_department',
    'Coordinator',
    'OTHER',
  ]);
  const [noticePeriodOptions, setNoticePeriodOptions] = useState([
    'Immediate joining',
    '15_days',
    '1_month',
    '2_months',
    '3_months',
    '4_months',
    '5_months',
    '6_months',
  ]);
  // const [countryOptions, setCountryOptions] = useState([
  //   'USA',
  //   'Canada',
  //   'India',
  // ]);
  // const [stateOptions, setStateOptions] = useState([
  //   'California',
  //   'Texas',
  //   'New York',
  //   // Add other states as needed
  // ]);
  // const [cityOptions, setCityOptions] = useState([
  //   'Los Angeles',
  //   'San Francisco',
  //   // Add other cities as needed
  // ]);

  const options = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Science", value: "Science" },
    { label: "Social Science", value: "Social Science" },
    { label: "Other", value: "Other" },
  ];

  const [isCurrentJob, setIsCurrentJob] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [countryId, setCountryId] = useState(skipToken);
  const [stateId, setStateId] = useState(skipToken);

  const handleFieldTouch = (fieldName) => {
    setTouchedFields((prev) => ({ ...prev, [fieldName]: true }));
  };
  
  const handleCurrentJobChange = (event) => {
    setIsCurrentJob(event.target.checked);
    setNewFormData((prevForm) => ({
      ...prevForm,
      currently_working: event.target.checked, 
    }));
    if (event.target.checked) {
      setProfessionalEndDate(null); 
    }
  };

  const handleAddForm = () => {
    setExperienceCount(experienceCount + 1);
    setExperienceArray([...experienceArray, { field1: '', field2: '', field3: '', field4: dayjs('2000-08-18'), field5: dayjs('2000-08-18') }]);
  };

  const handleRemoveForm = () => {
    if (experienceArray.length > 1) {
        setExperienceArray(experienceArray.slice(0, -1));
    } else {
        alert("Cannot remove the last experience entry.");
    }
};

  // const handleFormChange = (index, field, value) => {
  //   const updatedForms = [...experienceArray];
  //   updatedForms[index][field] = value;
  //   setExperienceArray(updatedForms);
  // };

  const handleFormChange = (index, field, value) => {
    setExperienceArray((prevArray) => {
      const updatedForms = [...prevArray];
      updatedForms[index] = {
        ...updatedForms[index],
        [field]: value,
      };
      return updatedForms;
    });
  };

  useEffect(() => {
    setNewFormData((prev) => ({
      ...prev,
      user_experiences: experienceArray,
    }));
  }, [experienceArray]);


  const handleEducationAddForm = () => {
    setEducationCount(educationCount + 1);
    setEducationArray([...educationArray, { field1: '', field2: '', field3: '', field4: dayjs('2000-08-18'), field5: dayjs('2000-08-18') }]);
  };

  const handleEducationRemoveForm = () => {
    if (educationArray.length > 1) {
        setEducationArray(educationArray.slice(0, -1));
    }
  };

  // const handleEducationFormChange = (index, field, value) => {
  //   const updatedForms = [...educationArray];
  //   updatedForms[index][field] = value;
  //   setEducationArray(updatedForms);
  // };

  const handleEducationFormChange = (index, field, value) => {
    setEducationArray((prevArray) => {
      const updatedForms = [...prevArray];
      updatedForms[index] = {
        ...updatedForms[index],
        [field]: value,
      };
      return updatedForms;
    });
  };

  useEffect(() => {
    setNewFormData((prev) => ({
      ...prev,
      user_educations: educationArray,
    }));
  }, [educationArray]);

  const handleMultiSelectChange = (selectedOptions) => {
    setSelected(selectedOptions);

    // Extract only the values from the selected options
    const selectedValues = selectedOptions.map(option => option.value);

    // Update the newFormData object
    setNewFormData({
      ...newFormData,
      subjects: selectedValues
    });
  };

  // const options = ["English","Hindi","Maths","Science","Social Science","Computer Science","Other"]

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleChangeBirth = (newValue) => {
    setbirthValue(newValue);
  };

  const handleChangeExperienceStartDate = (index, field, newValue) => {
    const updateDate = `${newValue.get('year')}-${String(newValue.get('month') + 1).padStart(2, 0)}-${String(
      newValue.get('date')
    ).padStart(2, 0)}`

    const updatedExperienceArray = [...experienceArray];
    updatedExperienceArray[index] = {
      ...updatedExperienceArray[index],
      field4: updateDate,
    };
    setExperienceArray(updatedExperienceArray);
  };

  const handleChangeExperienceEndDate = (index, field, newValue) => {
    const updateDate = `${newValue.get('year')}-${String(newValue.get('month') + 1).padStart(2, 0)}-${String(
      newValue.get('date')
    ).padStart(2, 0)}`

    const updatedExperienceArray = [...experienceArray];
    updatedExperienceArray[index] = {
      ...updatedExperienceArray[index],
      field5: updateDate,
    };
    setExperienceArray(updatedExperienceArray);
  };

  const handleChangeEducationStartDate = (index, field, newValue) => {
    const updateDate = `${newValue.get('year')}-${String(newValue.get('month') + 1).padStart(2, 0)}-${String(
      newValue.get('date')
    ).padStart(2, 0)}`

    const updatedEducationArray = [...educationArray];
    updatedEducationArray[index] = {
      ...updatedEducationArray[index],
      field4: updateDate,
    };
    setEducationArray(updatedEducationArray);
  };

  const handleChangeEducationEndDate = (index, field, newValue) => {
    const updateDate = `${newValue.get('year')}-${String(newValue.get('month') + 1).padStart(2, 0)}-${String(
      newValue.get('date')
    ).padStart(2, 0)}`

    const updatedEducationArray = [...educationArray];
    updatedEducationArray[index] = {
      ...updatedEducationArray[index],
      field5: updateDate,
    };
    setEducationArray(updatedEducationArray);
  };

  const handleChangeProfessionalStartDate = (newValue) => {
    setProfessionalStartDate(newValue);
  };

  const handleChangeProfessionalEndDate = (newValue) => {
    setProfessionalEndDate(newValue);
  };

  const handleChangeAdmission = (newValue) => {
    setadmissionValue(newValue);
  };
  const handleChangeGraduation = (newValue) => {
    setgraduationValue(newValue);
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    
  const NewCandidateSchema = Yup.object().shape({
    job_id: Yup.number().required('The job field is required'),
    first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    gender: Yup.string()
      .matches(/^((F|f)e)?(M|m)ale$/, 'Gender value invalid')
      .required('Gender value is required'),
    date_of_birth: Yup.string()
      .required('Date of birth is required')
      .matches(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/, 'Date of birth value invalid'),
    pincode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, 'Pincode is invalid')
      .required('Pincode is required'),
    street: Yup.string().required('Address is required').min(10, 'Too Short!'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    exp_months: Yup.number().required('Experience Months is required'),
    exp_years: Yup.number().required('Experience Years is required'),
    marital_status: Yup.string()
      .matches(/^((u|U)n)?(m|M)arried$/, 'Marital Status format invalid')
      .required('Marital Status is required'),
    institute: Yup.string().required('Institute is required'),
  });

  useEffect(() => {
    if (AddCandidateInfo.isError) {
      // console.log(AddCandidateInfo.error);
      showToast('error', 'Error adding candidate, please fill all the details');
    }
    if (AddCandidateInfo.isSuccess) {
      showToast('success', 'Successfully added candidate');
      navigate('/dashboard/candidates');
    }
  }, [AddCandidateInfo, navigate]);

  const { data: assessmentData, refetch: assessmentDataRefetech } = useGetAssesmentQuery();
  const [assessment, setAssessment] = useState(1);
  const handleChangeAssessment = (e) => setAssessment(e.target.value);
  const [UploadedFileName, setUploadedFileName] = useState('');
  const [Uploaded, setUploaded] = useState(false);

  const { data: jobData, refetch: jobDataRefetch } = useGetJobListQuery();
  const [job, setJob] = useState(0);
  const handleChangeJob = (e) => setJob(e.target.value);

  // const [newFormData, setNewFormData] = useState({
  //   job: candidateData?.job,
  //   first_name: candidateData?.first_name || '',
  //   middle_name: candidateData?.middle_name || '',
  //   last_name: candidateData?.last_name || '',
  //   mobile: candidateData?.mobile || '',
  //   alternate_mobile: candidateData?.alternate_mobile || '',
  //   email: candidateData?.email || '',
  //   alternate_email: candidateData?.alternate_email || '',
  //   gender: candidateData?.gender || '',
  //   marital_status: candidateData?.marital_status || '',
  //   bachlor_degree: candidateData?.bachlor_degree || '',
  //   professional_degree: candidateData?.professional_degree || '',
  //   date_of_birth: `${value.get('year')}-${String(value.get('month') + 1).padStart(2, 0)}-${String(
  //     value.get('date')
  //   ).padStart(2, 0)}`,
  //   age: candidateData?.age || '',
  //   pincode: candidateData?.pincode || '',
  //   street: candidateData?.street || '',
  //   city: candidateData?.city || '',
  //   state: candidateData?.state || '',
  //   country: candidateData?.country || '',
  //   exp_years: candidateData?.exp_years || '',
  //   highest_qualification: candidateData?.highest_qualification || '',
  //   current_job_title: candidateData?.current_job_title || '',
  //   cur_employer: candidateData?.cur_employer || '',
  //   professional_certificate: candidateData?.professional_certificate || '',
  //   curriculum_board: candidateData?.curriculum_board || '',
  //   fun_area: candidateData?.fun_area || '',
  //   professional_start_date: `${value.get('year')}-${String(value.get('month') + 1).padStart(2, 0)}-${String(
  //     value.get('date')
  //   ).padStart(2, 0)}`,
  //   professional_end_date: `${value.get('year')}-${String(value.get('month') + 1).padStart(2, 0)}-${String(
  //     value.get('date')
  //   ).padStart(2, 0)}`,
  //   subjects: [],
  //   skills: candidateData?.skills || '',
  //   notice_period: candidateData?.notice_period || '',
  //   resume: candidateData?.resume || null,
  //   certificate: candidateData?.certificate || '',
  //   cover_letter: candidateData?.cover_letter || '',
  //   pipeline_stage: candidateData?.pipeline_stage_status || '',
  //   pipeline_stage_status: candidateData?.pipeline_stage_status || '',
  //   user_experiences: experienceArray,
  //   user_educations: educationArray,
  //   headline: '',
  //   summary: '',

  // });
  const [newFormData, setNewFormData] = useState({
    job: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile: '',
    alternate_mobile: '',
    email: '',
    alternate_email: '',
    gender: '',
    marital_status: '',
    bachlor_degree: '',
    professional_degree: '',
    date_of_birth: '',
    age: '',
    pincode: '',
    street: '',
    city: '',
    state: '',
    country: '',
    exp_years: '',
    highest_qualification: '',
    current_job_title: '',
    cur_employer: '',
    professional_certificate: '',
    curriculum_board: '',
    fun_area: '',
    professional_start_date: '',
    professional_end_date: '',
    currently_working: false,
    subjects: [],
    skills: '',
    notice_period: '',
    resume: null,
    certificate: '',
    cover_letter: '',
    pipeline_stage: 'Applied',
    pipeline_stage_status: 'Applied',
    user_experiences: [],
    user_educations: [],
    headline: '',
    summary: '',
  });

  const { data: countryData, refetch: countryDataRefetch } = useGetCountryQuery();
  const { data: stateData, refetch: stateDataRefetch } = useGetStateQuery(countryId);
  const { data: cityData, refetch: cityDataRefetch } = useGetCityQuery(stateId);
  const [updateCandidate, { isLoading: isUpdating, isError: isUpdateError, isSuccess: isUpdateSuccess }] = useEditCandidateMutation();

  useEffect(() => {
    if (candidateData) {
        let dateOfBirthDate = '';
        if (candidateData?.date_of_birth && candidateData?.date_of_birth.trim() !== '') {
            dateOfBirthDate = dayjs(candidateData?.date_of_birth, 'YYYY-MM-DD');
        }
        setbirthValue(dateOfBirthDate)

        setExperienceArray(candidateData?.user_experiences || []);
        setEducationArray(candidateData?.user_educations || []);
        setSelected(candidateData?.subjects?.map(subject => ({ label: subject, value: subject })) || []);

        let selectedCountry = null;
        if(countryId === skipToken) {
            selectedCountry = countryData?.countries?.find(country => country.id === candidateData?.country);
            setCountryId(selectedCountry ? selectedCountry.id : skipToken);
        }
        else 
        {
            selectedCountry = countryData?.countries?.find(country => country.id === countryId);
            setCountryId(selectedCountry ? selectedCountry.id : skipToken);
        }
        let selectedState = null;
        if(stateId === skipToken) {
            selectedState = stateData?.states?.find(state => state.id === candidateData?.state);
            setStateId(selectedState ? selectedState.id : skipToken);
        }
        else
        {
            selectedState = stateData?.states?.find(state => state.id === stateId);
            setStateId(selectedState ? selectedState.id : skipToken);
        }

        setNewFormData({
            // job: candidateData?.job,
            job: candidateData?.job.map(job => job),
            first_name: candidateData?.first_name || '',
            middle_name: candidateData?.middle_name || '',
            last_name: candidateData?.last_name || '',
            mobile: candidateData?.mobile || '',
            alternate_mobile: candidateData?.alternate_mobile || '',
            email: candidateData?.email || '',
            alternate_email: candidateData?.alternate_email || '',
            gender: candidateData?.gender || '',
            marital_status: candidateData?.marital_status || '',
            bachlor_degree: candidateData?.bachlor_degree || '',
            professional_degree: candidateData?.professional_degree || '',
            date_of_birth: dateOfBirthDate ? dateOfBirthDate.format('YYYY-MM-DD') : '',
            age: dateOfBirthDate ? dayjs().diff(dateOfBirthDate, 'year') : '',
            pincode: candidateData?.pincode || '',
            street: candidateData?.street || '',
            country: selectedCountry ? selectedCountry.id : '',
            state: selectedState ? selectedState.id : '',
            city: candidateData?.city || '',
            exp_years: candidateData?.exp_years || '',
            highest_qualification: candidateData?.highest_qualification || '',
            current_job_title: candidateData?.current_job_title || '',
            cur_employer: candidateData?.cur_employer || '',
            professional_certificate: candidateData?.professional_certificate || '',
            curriculum_board: candidateData?.curriculum_board || '',
            fun_area: candidateData?.fun_area || '',
            professional_start_date: `${value.get('year')}-${String(value.get('month') + 1).padStart(2, 0)}-${String(
            value.get('date')
            ).padStart(2, 0)}`,
            professional_end_date: `${value.get('year')}-${String(value.get('month') + 1).padStart(2, 0)}-${String(
            value.get('date')
            ).padStart(2, 0)}`,
            currently_working: candidateData?.currently_working || false,
            subjects: candidateData?.subjects || [],
            skills: candidateData?.skills || '',
            notice_period: candidateData?.notice_period || '',
            resume: candidateData?.resume || null,
            certificate: candidateData?.certificate || '',
            cover_letter: candidateData?.cover_letter || '',
            pipeline_stage: candidateData?.pipeline_stage || '',
            pipeline_stage_status: candidateData?.pipeline_stage || '',
            user_experiences: candidateData?.user_experiences || [],
            user_educations: candidateData?.user_educations || [],
            headline: candidateData?.headline || '',
            summary: candidateData?.summary || '',
        });
        setIsCurrentJob(candidateData?.currently_working || false);
        }
    }, [candidateData, countryData, stateData]);

  
  useEffect(() => {
    if (countryId !== skipToken) {
      stateDataRefetch();
    }
  }, [countryId]);
  
  useEffect(() => {
    if (stateId !== skipToken) {
      cityDataRefetch();
    }
  }, [stateId]);
  
  useEffect(() => {
    countryDataRefetch();
  }, []);


  const [file, setFile] = useState(null);
  const [resume, setResume] = useState(null);
  const [resumeData, setResumeData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChanges = (event) => {
    const file = event.target.files[0];
    setResume(file);
    setNewFormData((prevForm) => ({
      ...prevForm,
      resume: file,
    }));
  };

  useEffect(() => {
    if (resume) {
      handleUpload();
    }
  }, [resume]);

  const handleUpload = () => {
    if (resume) {
    setIsLoading(true)
		setNewFormData({});
      const formData = new FormData();
      formData.append('resume', resume);
      
      fetch(apiUrl.candidateParseResume, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setResumeData(data)

          // for professional degree
          const dynamicProfessionalDegree = data.ProfessionalDegree;
          if (!professionalDegreeOptions.includes(dynamicProfessionalDegree)) {
            setProfessionalDegreeOptions((prevOptions) => [...prevOptions, dynamicProfessionalDegree]);
          }

          // for Highest Qualification Held
          let dynamicQualification = data.Qualification;
          if (dynamicQualification.length > 100) {
            dynamicQualification = dynamicQualification.substr(0, 90);
          }
          if (!qualificationOptions.includes(dynamicQualification)) {
            setQualificationOptions((prevOptions) => [...prevOptions, dynamicQualification]);
          }

          // Update the form data
          setNewFormData((prevFormData) => ({
            ...prevFormData,
            highest_qualification: dynamicQualification,
          }));

          // const dynamicCity = data.Address[0].City;
          // if (!cityOptions.includes(dynamicCity)) {
          //   setCityOptions((prevOptions) => [...prevOptions, dynamicCity]);
          // }

          // for Curriculum/Board
          const dynamicEducationBoard = data.Board;
          if (!educationBoardOptions.includes(dynamicEducationBoard)) {
            setEducationBoardOptions((prevOptions) => [...prevOptions, dynamicEducationBoard]);
          }

          // for Functional Area
          const dynamicJobRole = data.FunctionalArea;
          if (!jobRoleOptions.includes(dynamicJobRole)) {
            setJobRoleOptions((prevOptions) => [...prevOptions, dynamicJobRole]);
          }

          // for Notice Period
          const dynamicNoticePeriod = data.NoticePeriod;
          if (!noticePeriodOptions.includes(dynamicNoticePeriod)) {
            setNoticePeriodOptions((prevOptions) => [...prevOptions, dynamicNoticePeriod]);
          }

          // // for Country
          // const dynamicCountry = data.Address[0].Country;
          // if (!countryOptions.includes(dynamicCountry)) {
          //   setCountryOptions((prevOptions) => [...prevOptions, dynamicCountry]);
          // }

          // // for State
          // const dynamicState = data.Address[0].State;
          // if (!stateOptions.includes(dynamicState)) {
          //   setStateOptions((prevOptions) => [...prevOptions, dynamicState]);
          // }

          // for City
          // const dynamicCity = "Lahore";
          // if (!cityOptions.includes(dynamicCity)) {
          //   setCityOptions((prevOptions) => [...prevOptions, dynamicCity]);
          // }

          // for Skills
          const extractedSkills = data.SegregatedSkill.map(skillObj => skillObj.Skill);

          // for Experience
          const newArray = data.SegregatedExperience.map((originalObject) => {
            let formattedStartDate = '';
            let formattedEndDate = '';

            if (originalObject.StartDate && originalObject.StartDate.trim() !== '') {
              formattedStartDate = dayjs(originalObject.StartDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }

            if (originalObject.EndDate && originalObject.EndDate.trim() !== '') {
              formattedEndDate = dayjs(originalObject.EndDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
            return {
              field1: originalObject.Employer.EmployerName,
              field2: originalObject.JobProfile.FormattedName,
              field3: originalObject.JobDescription,
              field4: formattedStartDate,
              field5: formattedEndDate
            };
          });
          setExperienceArray(newArray);


          // for Education
          const newArray1 = data?.SegregatedQualification.map((originalObject) => {
            let formattedStartDate = '';
            let formattedEndDate = '';

            if (originalObject.StartDate && originalObject.StartDate.trim() !== '') {
              formattedStartDate = dayjs(originalObject.StartDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }

            if (originalObject.EndDate && originalObject.EndDate.trim() !== '') {
              formattedEndDate = dayjs(originalObject.EndDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
            const dynamicEducationDegree = originalObject.Degree.DegreeName;
            if (!educationalDegreeOptions.includes(dynamicEducationDegree)) {
              setEducationalDegreeOptions((prevOptions) => [...prevOptions, dynamicEducationDegree]);
            }
            return {
              field1: originalObject.Institution.Name,
              field2: originalObject.Degree.DegreeName,
              field3: originalObject.Degree.Specialization[0],
              field4: formattedStartDate,
              field5: formattedEndDate
            };
          });
          setEducationArray(newArray1)

          let dateOfBirthDate = '';
          if (data.DateOfBirth && data.DateOfBirth.trim() !== '') {
            dateOfBirthDate = dayjs(data.DateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD');
          }
          setbirthValue(dateOfBirthDate)

          const age = dateOfBirthDate ? dayjs().diff(dayjs(dateOfBirthDate, 'YYYY-MM-DD'), 'year') : '';

          let streetData = data.Address[0].Street;
          if (streetData.length > 100) {
            streetData = streetData.substr(0, 90);
          }

          setNewFormData({
            ...newFormData,
            first_name: data?.Name?.FirstName || '',
            middle_name: data?.Name?.MiddleName || '',
            last_name: data?.Name?.LastName || '',
            email: data?.Email[0]?.EmailAddress || '',
            mobile: data?.PhoneNumber[0]?.Number || '',
            // date_of_birth: data.DateOfBirth
            //   ? `${data.DateOfBirth.get('year')}-${String(data.DateOfBirth.get('month') + 1).padStart(2, 0)}-${String(data.DateOfBirth.get('date')).padStart(2, 0)}`
            //   : `${value.get('year')}-${String(value.get('month') + 1).padStart(2, 0)}-${String(
            //     value.get('date')
            //   ).padStart(2, 0)}`,
            date_of_birth: dateOfBirthDate,
            age,
            gender: data?.Gender || '',
            marital_status: data?.MaritalStatus || '',
            exp_years: '',
            // exp_years: parseInt(data.WorkedPeriod.TotalExperienceInYear, 10),
            cur_employer: data?.CurrentEmployer || '',
            current_job_title: data?.CurrentJobTitle || '',
            professional_degree: dynamicProfessionalDegree || '',
            highest_qualification: dynamicQualification || '',
            curriculum_board: dynamicEducationBoard || '',
            fun_area: dynamicJobRole || '',
            notice_period: dynamicNoticePeriod || '',
            street: streetData || '',
            // country: data.Address[0].Country || '',
            // state: data.Address[0].State || '',
            // city: data.Address[0].City || '',
            country: '',
            state: '',
            city: '',
            pincode: data.Address[0].ZipCode || '',
            skills: extractedSkills,
            user_experiences: newArray,
            user_educations: newArray1,
            headline: data.JobProfile,
            summary: data.Summary
          });
          // Handle the response from the server
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setIsLoading(false);
          // Handle errors
        });
    }
  };



  const handleFileChange = (event) => {
    const pdfFile = event.target.files[0];
    setNewFormData((prevForm) => ({
      ...prevForm,
      professional_certificate: pdfFile,
    }));
    // const reader = new FileReader();

    // reader.onload = () => {
    //   setFile(reader.result);
    // };

    // reader.readAsArrayBuffer(pdfFile);
  };

  const handleResumeFileChange = (e) => {
    const file = e.target.files[0];

    setNewFormData((prevForm) => ({
      ...prevForm,
      resume: file,
    }));
  }

  const handleCoverLetterFileChange = (e) => {
    const file = e.target.files[0];

    setNewFormData((prevForm) => ({
      ...prevForm,
      cover_letter: file,
    }));
  }

  const handleCertificateFileChange = (e) => {
    const file = e.target.files[0];

    setNewFormData((prevForm) => ({
      ...prevForm,
      certificate: file,
    }));
  }

  const isValidateCandidate = () => {
    let status = true;
    const requiredFields = ['first_name', 'last_name', 'email', 'mobile', 'street', 'country', 'state', 'city', 'date_of_birth','job','pipeline_stage'];
  
    const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegExp = /^\+?\d{10,14}$/;


    requiredFields.forEach(field => {
      if (!newFormData[field] || (typeof newFormData[field] === 'string' && newFormData[field].trim() === '')) {
        status = false;
        showToast('error', `Please fill ${field.replace('_', ' ')}`);
      }
    });

    if (!newFormData.date_of_birth || !dayjs(newFormData.date_of_birth).isValid()) {
      status = false;
      showToast('error', 'Date of birth is required and must be a valid date');
    }

    if (!emailRegExp.test(newFormData.email)) {
      status = false;
      showToast('error', 'Invalid email address');
    }

    if (newFormData.alternate_email && !emailRegExp.test(newFormData.alternate_email)) {
      status = false;
      showToast('error', 'Invalid alternate email address');
    }

    if (!phoneRegExp.test(newFormData.mobile)) {
      status = false;
      showToast('error', 'Invalid mobile number');
    }

    if (newFormData.alternate_mobile && !phoneRegExp.test(newFormData.alternate_mobile)) {
      status = false;
      showToast('error', 'Invalid alternate mobile number');
    }

    // if (experienceArray && experienceArray.length > 0) {
    //   experienceArray.forEach((item, index) => {
    //     if (!item.field1 || item.field1.trim() === '') { // Assuming field1 is company_name
    //       // console.error(`Validation error in experience at index ${index}: company_name is empty`);
    //       status = false;
    //       showToast('error', 'Please fill company name in all experience entries');
    //     }
    //   });
    // }
    // if (educationArray && educationArray.length > 0) {
    //   educationArray.forEach((item, index) => {
    //     if (!item.field1 || item.field1.trim() === '') { // Assuming field1 corresponds to school_name
    //       // console.error(`Validation error in education at index ${index}: school_name is empty`);
    //       status = false;
    //       showToast('error', 'Please fill school name in all education entries');
    //     }
    //   });
    // }
  
    return status;
  };

  useEffect(() => {
      getRefetch();
  }, []);


  const convertDataToFormData = async (data) => {
    const bDate = new Date(newFormData.date_of_birth).toISOString().slice(0, 10);
    const truncatedQualification = newFormData.highest_qualification.substr(0, 90);
    
    const formData = new FormData();

    // Append the JSON data as a field
    formData.append('job', newFormData.job);
    formData.append('first_name', newFormData.first_name);
    formData.append('middle_name', newFormData.middle_name);
    formData.append('last_name', newFormData.last_name);
    formData.append('mobile', newFormData.mobile);
    formData.append('alternate_mobile', newFormData.alternate_mobile);
    formData.append('email', newFormData.email);
    formData.append('alternate_email', newFormData.alternate_email);
    formData.append('gender', newFormData.gender);
    formData.append('marital_status', newFormData.marital_status);
    formData.append('bachlor_degree', newFormData.bachlor_degree);
    formData.append('professional_degree', newFormData.professional_degree);
    formData.append('date_of_birth', bDate);
    formData.append('age', newFormData.age);
    formData.append('pincode', newFormData.pincode);
    formData.append('state', newFormData.state);
    formData.append('country', newFormData.country);
    formData.append('city', newFormData.city);
    formData.append('street', newFormData.street);
    formData.append('exp_years', newFormData.exp_years);
    formData.append('highest_qualification', truncatedQualification);
    formData.append('current_job_title', newFormData.current_job_title);
    formData.append('cur_employer', newFormData.cur_employer);
    formData.append('curriculum_board', newFormData.curriculum_board);
    formData.append('fun_area', newFormData.fun_area);
    formData.append('professional_start_date', newFormData.professional_start_date);
    formData.append('professional_end_date', newFormData.professional_end_date);
    formData.append('currently_working', newFormData.currently_working);
    // formData.append('subjects', JSON.stringify(newFormData.subjects));
    formData.append('notice_period', newFormData.notice_period);
    formData.append('skills', newFormData.skills);
    formData.append('pipeline_stage_status', newFormData.pipeline_stage);
    formData.append('pipeline_stage', newFormData.pipeline_stage);
    formData.append('headline', newFormData.headline);
    formData.append('summary', newFormData.summary);
    // Append files
    // formData.append('professional_certificate', newFormData.professional_certificate);
    // formData.append('resume', newFormData.resume);
    // formData.append('cover_letter', newFormData?.cover_letter);
    // formData.append('certificate', newFormData?.certificate);
    if (newFormData?.cover_letter instanceof File) {
      formData.append('cover_letter', newFormData.cover_letter);
    }
    if (newFormData?.certificate instanceof File) {
      formData.append('certificate', newFormData.certificate);
    }
    if (newFormData?.resume instanceof File) {
      formData.append('resume', newFormData.resume);
    }
    formData.append('user_experiences', JSON.stringify(newFormData.user_experiences));
    formData.append('user_educations', JSON.stringify(newFormData.user_educations));
    newFormData.subjects.forEach((subject, index) => {
      formData.append(`subjects[${index}]`, subject);
    });
    await AddCandidate(formData);
  };


  const convertEditDataToFormData = async (data) => {
    const formData = new FormData();
    const truncatedQualification = newFormData.highest_qualification.substr(0, 90);

    if (newFormData.job.length === 0) {
      throw new Error("Job field cannot be empty");
    }
    newFormData.job.forEach((jobId) => {
      formData.append('job', jobId);  
    });
    formData.append('first_name', newFormData.first_name);
    formData.append('middle_name', newFormData.middle_name);
    formData.append('last_name', newFormData.last_name);
    formData.append('mobile', newFormData.mobile);
    formData.append('alternate_mobile', newFormData.alternate_mobile);
    formData.append('email', newFormData.email);
    formData.append('alternate_email', newFormData.alternate_email);
    formData.append('gender', newFormData.gender);
    formData.append('marital_status', newFormData.marital_status);
    formData.append('bachlor_degree', newFormData.bachlor_degree);
    formData.append('professional_degree', newFormData.professional_degree);
    formData.append('date_of_birth', newFormData.date_of_birth);
    formData.append('age', newFormData.age);
    formData.append('pincode', newFormData.pincode);
    formData.append('state', newFormData.state);
    formData.append('country', newFormData.country);
    formData.append('city', newFormData.city);
    formData.append('street', newFormData.street);
    formData.append('exp_years', newFormData.exp_years);
    formData.append('highest_qualification', truncatedQualification);
    formData.append('current_job_title', newFormData.current_job_title);
    formData.append('cur_employer', newFormData.cur_employer);
    formData.append('curriculum_board', newFormData.curriculum_board);
    formData.append('fun_area', newFormData.fun_area);
    formData.append('professional_start_date', newFormData.professional_start_date);
    formData.append('professional_end_date', newFormData.professional_end_date);
    formData.append('currently_working', newFormData.currently_working);
    // formData.append('subjects', JSON.stringify(newFormData.subjects));
    formData.append('notice_period', newFormData.notice_period);
    formData.append('skills', newFormData.skills);
    formData.append('pipeline_stage_status', newFormData.pipeline_stage);
    formData.append('pipeline_stage', newFormData.pipeline_stage);
    formData.append('headline', newFormData.headline);
    formData.append('summary', newFormData.summary);
    // Append files
    // formData.append('professional_certificate', newFormData.professional_certificate);
    // formData.append('resume', newFormData.resume);
    // formData.append('cover_letter', newFormData?.cover_letter);
    // formData.append('certificate', newFormData?.certificate);
    if (newFormData?.cover_letter instanceof File) {
      formData.append('cover_letter', newFormData.cover_letter);
    }
    if (newFormData?.certificate instanceof File) {
      formData.append('certificate', newFormData.certificate);
    }
    if (newFormData?.professional_certificate instanceof File) {
      formData.append('professional_certificate', newFormData.professional_certificate);
    }
    formData.append('user_experiences', JSON.stringify(newFormData.user_experiences));
    formData.append('user_educations', JSON.stringify(newFormData.user_educations));
    newFormData.subjects.forEach((subject, index) => {
        formData.append(`subjects[${index}]`, subject);
    });
    return formData
  };

  const handleChangenewFormData = (name, value) => {
    if (name === 'country') {
      const selectedCountry = countryData?.countries?.find(country => country.id === value);
      setCountryId(selectedCountry ? selectedCountry.id : skipToken);
      setNewFormData((prev) => ({
        ...prev,
        country: selectedCountry ? selectedCountry.id : '',
        state: '',
        city: ''
      }));
    } else if (name === 'state') {
      const selectedState = stateData?.states?.find(state => state.id === value);
      setStateId(selectedState ? selectedState.id : skipToken);
      setNewFormData((prev) => ({
        ...prev,
        state: selectedState ? selectedState.id : '',
        city: ''
      }));
    } else if (name === 'city') {
      const selectedCity = cityData?.cities?.find(city => city.id === value);
      setNewFormData((prev) => ({
        ...prev,
        city: selectedCity ? selectedCity.id : '',
      }));
    } else if (name === 'job') {
      setNewFormData((prev) => ({
        ...prev,
        job: Array.isArray(value) ? value : [value],
      }));
    } else {
      setNewFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };


  const handleSubmit = async () => {
    if (editCandidateId) {
      setIsUpdate(true);
    }
  
    if (!isValidateCandidate()) {
      showToast('error', 'Please fill all required fields correctly.');
      return; 
    }
  
    if (editCandidateId) {
      handleUpdateCandidate();
    } else {
      convertDataToFormData(newFormData);
    }
  };
  
  const handleUpdateCandidate = async () => {
    const formData = await convertEditDataToFormData(newFormData);
 
    updateCandidate({ data: formData, id: editCandidateId })
      .unwrap()
      .then(response => {
        showToast('success', 'Candidate updated successfully');
        setIsUpdate(false);
        navigate('/dashboard/candidates');
      })
      .catch(error => {
        console.error('PUT request failed:', error);
        showToast('error', 'Error updating candidate');
      });
  };

  // if (isLoading) {
  //   return (
  //     <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
  //       <CircularProgress />
  //     </Container>
  //   );
  // }

  function handleSubject(e) {
    if (e.target.value === "Other") {
      setShowTextField(true);
    }
    let selectedSubjects = [...newFormData.subjects];
    if (e.target.checked) {
      selectedSubjects.push(e.target.value);
    } else {
      selectedSubjects = selectedSubjects.filter((item) => item !== e.target.value);
    }
    handleChangenewFormData('subjects', selectedSubjects);
  }

  function handleCertificates(e) {
    const selectedCerti = [...newFormData.professional_certificate];
    const { name, value } = e.target;
    setNewFormData({
      ...newFormData,
      [name]: value,
    });
    handleChangenewFormData('professional_certificate');
  }

  const extractFileName = (url) => {
    if (typeof url === 'string') {
      const parts = url.split('/');
      return parts[parts.length - 1];
    }
    // Handle the case where url is not a string (e.g., null, undefined, or another type)
    // You can return a default value or handle it according to your requirements.
    return null; // or throw an error, return an empty string, etc.
  };


  return (

    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={8}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Link to="/dashboard/candidates">
              <img src={Back} alt="Go Back" />
            </Link>
            <Typography 
              variant="h4" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              {editCandidateId ? 'Update' : 'Create'} a Candidate
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} container justifyContent={{ xs: 'center', md: 'flex-end' }}>
          <Button variant="contained" onClick={handleSubmit}>
            {editCandidateId ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    <Card sx={{ marginTop: '1%', padding: '10px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography 
            variant="h5" 
            sx={{ 
              marginBottom: '2%', 
              marginLeft: '4%', 
              marginTop: '3%', 
              backgroundColor: 'transparent !important' 
            }}
          >
            Attachment
          </Typography>
        </Grid>
        <Grid item xs={12} container alignItems="center" spacing={2} sx={{ marginLeft: '7%' }}>
          <Grid item>
            <InputLabel>Resume</InputLabel>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }} md={4}>
            <Input
              type="file"
              sx={{ width: '100%' }}
              required
              accept=".pdf,.doc,.docx"
              aria-describedby="my-helper-text"
              id="upload-resume"
              label="Upload Resume"
              variant="standard"
              onChange={handleFileChanges}
            />
          </Grid>
          {/* <Grid item>
            <Button variant="contained" size="small" disabled={!resume} onClick={handleUpload}>
              Upload Resume
            </Button>
          </Grid> */}
          {newFormData.resume && typeof newFormData.resume === 'string' && (
            <>
              <Grid item>
                <a href={newFormData.resume} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" size="small">
                    View Current Resume
                  </Button>
                </a>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ marginTop: 1 }} md={4}>
                <TextField
                  sx={{ width: '100%' }}
                  variant="standard"
                  value={extractFileName(newFormData.resume)}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography 
            variant="h5" 
            sx={{ 
              width: '300px',
              marginBottom: '2%', 
              marginLeft: '4%', 
              marginTop: '5%', 
              backgroundColor: 'transparent !important' 
            }}
          >
            Personal Details
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2} sx={{ marginLeft: '7%' }}>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              required
              id="standard-required"
              label="First Name"
              variant="standard"
              value={newFormData?.first_name || ''}
              name="first_name"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              id="standard-required"
              label="Middle Name"
              variant="standard"
              value={newFormData?.middle_name || ''}
              name="middle_name"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              required
              id="standard-required"
              label="Last Name"
              variant="standard"
              value={newFormData?.last_name || ''}
              name="last_name"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              required
              id="standard-required"
              label="Email"
              variant="standard"
              value={newFormData?.email || ''}
              name="email"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
              onBlur={() => handleFieldTouch('email')}
              error={touchedFields.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newFormData?.email)}
              helperText={touchedFields.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newFormData?.email) ? 'Invalid email address' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              id="standard-required"
              label="Alternative Email"
              variant="standard"
              value={newFormData?.alternate_email || ''}
              name="alternate_email"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
              onBlur={() => handleFieldTouch('alternate_email')}
              error={touchedFields.alternate_email && newFormData?.alternate_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newFormData?.alternate_email)}
              helperText={touchedFields.alternate_email && newFormData?.alternate_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newFormData?.alternate_email) ? 'Invalid email address' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              required
              type="tel"
              id="standard-required"
              label="Mobile Number"
              variant="standard"
              value={newFormData?.mobile || ''}
              name="mobile"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
              onBlur={() => handleFieldTouch('mobile')}
              error={touchedFields.mobile && !/^\d+$/.test(newFormData?.mobile)}
              helperText={touchedFields.mobile && !/^\d+$/.test(newFormData?.mobile) ? 'Invalid mobile number' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              type="tel"
              id="standard-required"
              label="Alternative Mobile Number"
              variant="standard"
              value={newFormData?.alternate_mobile || ''}
              name="alternate_mobile"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
              onBlur={() => handleFieldTouch('alternate_mobile')}
              error={touchedFields.alternate_mobile && newFormData?.alternate_mobile && !/^\d+$/.test(newFormData?.alternate_mobile)}
              helperText={touchedFields.alternate_mobile && newFormData?.alternate_mobile && !/^\d+$/.test(newFormData?.alternate_mobile) ? 'Invalid mobile number' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                sx={{ width: '90%' }}
                disableFuture
                label="Date of Birth"
                inputFormat="YYYY-MM-DD"
                value={birthvalue}
                onChange={(e) => {
                  const date = dayjs(e);
                  const today = dayjs();
                  const age = today.diff(date, 'year');
                  handleChangeBirth(date);
                  handleChangenewFormData('age', age);
                  handleChangenewFormData('date_of_birth', date.format('YYYY-MM-DD'));
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              type="number"
              id="standard-required"
              label="Age"
              variant="standard"
              value={newFormData?.age || ''}
              name="age"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
              <Select
                sx={{ width: '90%' }}
                margin="dense"
                variant="standard"
                fullWidth
                value={newFormData?.gender || ''}
                name="gender"
                label="Gender"
                onChange={(e) => handleChangenewFormData(e?.target?.name, e?.target?.value)}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Marital Status</InputLabel>
              <Select
                sx={{ width: '90%' }}
                margin="dense"
                variant="standard"
                fullWidth
                value={newFormData?.marital_status || ''}
                name="marital_status"
                label="Marital Status"
                onChange={(e) => handleChangenewFormData(e?.target?.name, e?.target?.value)}
              >
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Divorced">Divorced</MenuItem>
                <MenuItem value="Widow">Widow</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography 
            variant="h5" 
            sx={{ 
              marginBottom: '2%', 
              marginLeft: '4%', 
              marginTop: '5%', 
              backgroundColor: 'transparent !important' 
            }}
          >
            Professional Details
          </Typography>
        </Grid>  
        <Grid item xs={12} container spacing={2} sx={{ marginLeft: '7%' }}>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              // required
              id="standard-required"
              label="Years of Experience"
              variant="standard"
              value={newFormData?.exp_years || ''}
              name="exp_years"
              onChange={(e) => handleChangenewFormData(e.target.name, +e.target.value)}
            />
          </Grid>  
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Highest Qualification held</InputLabel>
              <Select
                sx={{ width: '90%' }}
                margin="dense"
                variant="standard"
                fullWidth
                value={newFormData?.highest_qualification || ''}
                name="highest_qualification"
                label="Select"
                inputProps={{ maxLength: 100 }} 
                onChange={(e) => handleChangenewFormData('highest_qualification', e.target.value)}
              >
                {qualificationOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>  
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              // required
              id="standard-required"
              label="Current Employer"
              variant="standard"
              value={newFormData?.cur_employer || ''}
              name="cur_employer"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={1} container alignItems="center">
            <Checkbox
              checked={isCurrentJob}
              onChange={handleCurrentJobChange}
              name="currentJob"
              color="primary"
            />
            <Typography component="span" sx={{ backgroundColor: 'transparent !important' }}>I am currently working here</Typography>
          </Grid> 
          <Grid item xs={12} sm={2} sx={{ marginTop: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                sx={{ width: '90%' }}
                disableFuture
                label="From"
                inputFormat="YYYY-MM-DD"
                value={professionalStartDate}
                onChange={(e) => {
                  const date = dayjs(e);
                  const year = date.year();
                  const todaysDate = dayjs(new Date().toISOString());
                  const currentYear = todaysDate.year();
                  handleChangeProfessionalStartDate(e)
                  handleChangenewFormData(
                    'professional_start_date',
                    `${date.get('year')}-${String(date.get('month') + 1).padStart(2, 0)}-${String(
                      date.get('date')
                    ).padStart(2, 0)}`
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ marginTop: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                sx={{ width: '90%' }}
                disableFuture
                label="To"
                inputFormat="YYYY-MM-DD"
                value={professionalEndDate}
                onChange={(e) => {
                  const date = dayjs(e);
                  handleChangeProfessionalEndDate(e);
                  handleChangenewFormData(
                    'professional_end_date',
                    `${date.get('year')}-${String(date.get('month') + 1).padStart(2, 0)}-${String(date.get('date')).padStart(2, 0)}`
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                disabled={isCurrentJob} 
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              // required
              id="standard-required"
              label="Current Job Title"
              variant="standard"
              value={newFormData?.current_job_title}
              name="current_job_title"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Professional Degree</InputLabel>
              <Select
                sx={{ width: '90%' }}
                margin="dense"
                variant="standard"
                fullWidth
                value={newFormData?.professional_degree || ''}
                name="professional_degree"
                label="Select"
                onChange={(e) => handleChangenewFormData(e?.target?.name, e?.target?.value)}
              >
                {professionalDegreeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Curriculum/Board</InputLabel>
              <Select
                sx={{ width: '90%' }}
                margin="dense"
                variant="standard"
                fullWidth
                value={newFormData?.curriculum_board || ''}
                name="curriculum_board"
                label="Select"
                onChange={(e) => handleChangenewFormData(e?.target?.name, e?.target?.value)}
              >
                {educationBoardOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Functional Area</InputLabel>
              <Select
                sx={{ width: '90%' }}
                margin="dense"
                variant="standard"
                fullWidth
                value={newFormData?.fun_area || ''}
                name="fun_area"
                label="Select"
                onChange={(e) => handleChangenewFormData(e?.target?.name, e?.target?.value)}
              >
                {jobRoleOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Notice Period</InputLabel>
              <Select
                sx={{ width: '90%' }}
                margin="dense"
                variant="standard"
                fullWidth
                value={newFormData?.notice_period || ''}
                name="notice_period"
                label="Select"
                onChange={(e) => handleChangenewFormData(e?.target?.name, e?.target?.value)}
              >
                {noticePeriodOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Job</InputLabel>
              <Select
                sx={{ width: '90%' }}
                margin="dense"
                variant="standard"
                fullWidth
                name="job"
                multiple
                value={newFormData.job || []}
                label="job"
                onChange={(e) => handleChangenewFormData(e?.target?.name, e?.target?.value)}
              >
                {jobData &&
                  jobData?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item?.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '90%' }}>
              <Typography variant="body1"
              sx={{ 
                fontWeight: 'normal',
                backgroundColor: 'transparent !important'
              }}
              >
                Subjects
              </Typography>
              <MultiSelect
                options={options}
                value={selected}
                onChange={handleMultiSelectChange}
                labelledBy="Select"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography 
            variant="h5" 
            sx={{ 
              marginBottom: '2%', 
              marginLeft: '4%', 
              marginTop: '5%', 
              backgroundColor: 'transparent !important' 
            }}
          >
            Address
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2} sx={{ marginLeft: '7%' }}>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              // required
              id="standard-required"
              label="Street"
              variant="standard"
              value={newFormData?.street || ''}
              name="street"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '90%' }}>
              <Typography variant="body1"
              sx={{ 
                fontWeight: 'normal',
                backgroundColor: 'transparent !important'
              }}
              >
                Country
              </Typography>
              <MultiSelect
                options={countryData?.countries?.map(country => ({ label: country.name, value: country.id })) || []}
                value={newFormData?.country ? [{ label: countryData?.countries?.find(country => country.id === newFormData.country)?.name, value: newFormData.country }] : []}
                onChange={(selected) => handleChangenewFormData('country', selected[0]?.value || '')}
                labelledBy="Select Country"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '90%' }}>
              <Typography variant="body1"
              sx={{ 
                fontWeight: 'normal',
                backgroundColor: 'transparent !important'
              }}
              >
                State
              </Typography>
              <MultiSelect
                options={stateData?.states?.map(state => ({ label: state.name, value: state.id })) || []}
                value={newFormData?.state ? [{ label: stateData?.states?.find(state => state.id === newFormData.state)?.name, value: newFormData.state }] : []}
                onChange={(selected) => handleChangenewFormData('state', selected[0]?.value || '')}
                labelledBy="Select State"
                overrideStrings={{
                  noOptions: "No Select a country", // Custom message
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '90%' }}>
              <Typography variant="body1"
                sx={{ 
                  fontWeight: 'normal',
                  backgroundColor: 'transparent !important'
                }}
              >
                City
              </Typography>
              <MultiSelect
              options={cityData?.cities?.map(city => ({ label: city.name, value: city.id })) || []}
              value={newFormData?.city ? [{ label: cityData?.cities?.find(city => city.id === newFormData.city)?.name, value: newFormData.city }] : []}
              onChange={(selected) => handleChangenewFormData('city', selected[0]?.value || '')}
              labelledBy="Select City"
              overrideStrings={{
                noOptions: "No Select a state", // Custom message
              }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              // required
              type="number"
              id="standard-required"
              label="Pin code"
              variant="standard"
              value={newFormData?.pincode}
              name="pincode"
              onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
            marginBottom: '2%',
            marginLeft: '4%',
            marginTop: '5%',
            backgroundColor: 'transparent !important'
            }}
          >
            Skills
          </Typography>
        </Grid>
        <Grid item xs={12}  container spacing={2} sx={{ marginLeft: '7%' }}>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <AntSelect
              mode="tags"
              style={{ width: '90%' }}
              value={newFormData?.skills || []}
              onChange={(value) => handleChangenewFormData('skills', value)}
              tokenSeparators={[',']}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
            marginBottom: '2%',
            marginLeft: '4%',
            marginTop: '5%',
            backgroundColor: 'transparent !important'
            }}
          >
            Experience
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: '7%' }}>
          {experienceArray.map((formData, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
              <TextField
                sx={{ width: '90%' }}
                // required
                id="standard-required"
                label="Name of Company"
                value={formData?.field1}
                variant="standard"
                name="company_name"
                onChange={(e) => handleFormChange(index, 'field1', e.target.value)}
              />
            </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
              sx={{ width: '90%' }}
              id="standard-required"
              label="Designation"
              variant="standard"
              value={formData?.field2}
              name="designations"
              onChange={(e) => handleFormChange(index, 'field2', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 1 }}>
            <FormControl fullWidth variant="standard">
              <TextareaAutosize
              id="my-textarea"
              aria-label="textarea"
              placeholder="Job Responsibilities"
              name="job_responsibility"
              value={formData?.field3}
              onChange={(e) => handleFormChange(index, 'field3', e.target.value)}
              minRows={5}
              style={{ width: '95%', padding: '10px' }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ marginTop: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
              sx={{ width: '90%' }}
              disableFuture
              label="From"
              inputFormat="YYYY-MM-DD"
              value={formData?.field4}
              onChange={(e) => handleChangeExperienceStartDate(index, 'field4', e)}
              renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ marginTop: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
              sx={{ width: '90%' }}
              disableFuture
              label="To"
              inputFormat="YYYY-MM-DD"
              value={formData?.field5}
              onChange={(e) => handleChangeExperienceEndDate(index, 'field5', e)}
              renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        ))}
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleAddForm}>Add Experience</Button>
          </Grid>
          {experienceArray.length > 1 && (
          <Grid item>
            <Button variant="contained" color="secondary" onClick={handleRemoveForm}>Remove Experience</Button>
          </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography
        variant="h5"
        sx={{
        marginBottom: '2%',
        marginLeft: '4%',
        marginTop: '5%',
        backgroundColor: 'transparent !important'
        }}
        >
          Education
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginLeft: '7%' }}>
        {educationArray.map((formData, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
            sx={{ width: '90%' }}
            // required
            id="standard-required"
            label="School Name"
            variant="standard"
            value={formData?.field1 || ''}
            name="school_name"
            onChange={(e) => handleEducationFormChange(index, 'field1', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Degree</InputLabel>
              <Select
                sx={{ width: '90%' }}
                margin="dense"
                variant="standard"
                fullWidth
                value={formData?.field2 || ''}
                name="Degree"
                label="Select"
                onChange={(e) => handleEducationFormChange(index, 'field2', e.target.value)}
              >
                {educationalDegreeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                {option}
                </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
            <TextField
            sx={{ width: '90%' }}
            margin="dense"
            variant="standard"
            label="Specialization"
            fullWidth
            value={formData?.field3 || ''}
            name="Specialization"
            onChange={(e) => handleEducationFormChange(index, 'field3', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={{ marginTop: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              sx={{ width: '90%' }}
              disableFuture
              label="Start Date"
              inputFormat="YYYY-MM-DD"
              value={formData?.field4}
              onChange={(e) => handleChangeEducationStartDate(index, 'field4', e)}
              renderInput={(params) => <TextField {...params} />}
            />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ marginTop: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              sx={{ width: '90%' }}
              disableFuture
              label="End Date"
              inputFormat="YYYY-MM-DD"
              value={formData?.field5}
              onChange={(e) => handleChangeEducationEndDate(index, 'field5', e)}
              renderInput={(params) => <TextField {...params} />}
            />
            </LocalizationProvider>
          </Grid>
        </Grid>
        ))}
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleEducationAddForm}>Add Education</Button>
          </Grid>
          {educationArray.length > 1 && (
          <Grid item>
            <Button variant="contained" color="secondary" onClick={handleEducationRemoveForm}>Remove Education</Button>
          </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
          marginBottom: '2%',
          marginLeft: '4%',
          marginTop: '5%',
          backgroundColor: 'transparent !important'
          }}
        >
        Attachment
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2} sx={{ marginLeft: '7%' }}>
        <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
        <InputLabel sx={{ textAlign: 'left' }}>Cover Letter</InputLabel>
        <Input
          type="file"
          sx={{ width: '90%' }}
          required
          aria-describedby="my-helper-text"
          id="standard-required"
          label="Upload Resume"
          variant="standard"
          name="cover_letter"
          onChange={handleCoverLetterFileChange}
        />
        <Typography>{extractFileName(newFormData?.cover_letter)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
        <InputLabel sx={{ textAlign: 'left' }}>Certificate</InputLabel>
        <Input
          type="file"
          sx={{ width: '90%' }}
          required
          aria-describedby="my-helper-text"
          id="standard-required"
          label="Upload Resume"
          variant="standard"
          name="certificate"
          onChange={handleCertificateFileChange}
        />
        <Typography>{extractFileName(newFormData?.certificate)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
          <Select
            sx={{ width: '90%' }}
            margin="dense"
            variant="standard"
            fullWidth
            value={newFormData?.pipeline_stage}
            name="pipeline_stage"
            label="Status"
            onChange={(e) => handleChangenewFormData(e?.target?.name, e?.target?.value)}
          >
            <MenuItem value="Applied">Applied</MenuItem>
            <MenuItem value="Shortlisted">Shortlisted</MenuItem>
            <MenuItem value="Interview">Interview</MenuItem>
            <MenuItem value="Offered">Offered</MenuItem>
            <MenuItem value="Hired">Hired</MenuItem>
            <MenuItem value="Onboarded">Onboarded</MenuItem>
            <MenuItem value="Associated_Screening">Associated Screening</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
      </Grid>
    </Card>
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 1000, 
        }}
      >
        <Button variant="contained" onClick={handleSubmit}>
          {editCandidateId ? 'Update' : 'Create'}
        </Button>
      </Box>
    </>
  );
}

export default NewcreateCandidate;
