
const OperatorMappings = () => ({
    text: [
      { name: 'contains', label: 'contains' },
      { name: 'notContains', label: 'does not contain' },
      { name: 'equals', label: 'equals' },
      { name: 'notEqual', label: 'not equal' },
      { name: 'startsWith', label: 'begins with' },
      { name: 'endWith', label: 'ends with' },
      { name: 'blank', label: 'is blank' },
      { name: 'notBlank', label: 'is not blank' },
    ],
    number: [
      { name: 'equals', label: '=' },
      { name: 'notEqual', label: '!=' },
      { name: 'greaterThan', label: '>' },
      { name: 'greaterThanOrEqual', label: '>=' },
      { name: 'lessThan', label: '<' },
      { name: 'lessThanOrEqual', label: '<=' },
      { name: 'blank', label: 'is blank' },
      { name: 'notBlank', label: 'is not blank' },
    ],
    date: [
      { name: 'equals', label: '=' },
      { name: 'notEqual', label: '!=' },
      { name: 'greaterThan', label: '>' },
      { name: 'greaterThanOrEqual', label: '>=' },
      { name: 'lessThan', label: '<' },
      { name: 'lessThanOrEqual', label: '<=' },
      { name: 'blank', label: 'is blank' },
      { name: 'notBlank', label: 'is not blank' },
    ],
    boolean: [
      { name: 'true', label: 'is true' },
      { name: 'false', label: 'is false' },
    ],
    object: [
      { name: 'contains', label: 'contains' },
      { name: 'notContains', label: 'does not contain' },
      { name: 'equals', label: 'equals' },
      { name: 'notEqual', label: 'not equal' },
      { name: 'blank', label: 'is blank' },
      { name: 'notBlank', label: 'is not blank' },
    ],
    time: [
      { name: 'equals', label: '=' },
      { name: 'notEqual', label: '!=' },
      { name: 'greaterThan', label: '>' },
      { name: 'greaterThanOrEqual', label: '>=' },
      { name: 'lessThan', label: '<' },
      { name: 'lessThanOrEqual', label: '<=' },
      { name: 'blank', label: 'is blank' },
      { name: 'notBlank', label: 'is not blank' },
    ],
});

  export default OperatorMappings;