import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { AutoSizer } from 'react-virtualized';
import {
  Grid,
  TextField,
  Button,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from '../Iconify';
import {
  useGetJobNotesListQuery,
  useGetNotesTypesQuery,
  useAddJobNotesMutation,
  useDeleteJobNotesMutation,
  useUpdateJobNotesMutation
} from '../../redux/services/notes/NotesServices';
import { showToast } from '../../utils/toast';

const useStyles = makeStyles({
    oddRow: {
      backgroundColor: '#f9f9f9',
    },
    evenRow: {
      backgroundColor: '#ffffff',
    },
  });

const JobNotes = (props) => {
  const classes = useStyles();
  const { data: jobNotesData, refetch } = useGetJobNotesListQuery(props.jobId);
  const { data: candidateNoteType } = useGetNotesTypesQuery();
  const [addJobNotes, addJobNotesInfo] = useAddJobNotesMutation();
  const [updateJobNotes, updateJobNotesInfo] = useUpdateJobNotesMutation();
  const [deleteJobNotes, deleteJobNotesinfo] = useDeleteJobNotesMutation();
  const [noteText, setNoteText] = useState('');
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editingNoteText, setEditingNoteText] = useState('');
  const [selectedNoteType, setSelectedNoteType] = useState('');
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [addToCandidates, setAddToCandidates] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState(null);

  useEffect(() => { 
    refetch()
  }, [props.jobId])

  const handleChange = (e) => {
    setSelectedNoteType(e.target.value);
  };

  const handleClickOpen = () => {
    setIsEditMode(false);
    setNoteText('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingNoteId(null);
    setEditingNoteText('');
  };

  const handleDialogOpen = (id) => {
    setNoteIdToDelete(id);
    setDialogOpen(true);
  };
  
  const handleDialogClose = () => {
    setDialogOpen(false);
    setNoteIdToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (noteIdToDelete !== null) {
      const result = await deleteJobNotes(noteIdToDelete);
      if (result.data) {
        showToast('success', 'Note deleted successfully');
        refetch();
      } else {
        showToast('error', 'Failed to delete note');
      }
      handleDialogClose();
    }
  };

  const notesChange = (e) => {
    setNoteText(e.target.value);
  };

  const addNotesHandler = async () => {
    const result = await addJobNotes({
      job: props.jobId,
      note: noteText,
      addToCandidates
    });
    if (result.error) {
      showToast('error', result.error.data.msg || 'Failed to add note');
    } else {
      showToast('success', 'Note added successfully');
    }
    setNoteText('');
    refetch();
    handleClose();
  };

  const onEditHandler = (note) => {
    setIsEditMode(true);
    setEditingNoteId(note.id);
    setEditingNoteText(note.note);
    setOpen(true);
  };

  const saveEditHandler = async () => {
    const result = await updateJobNotes({
      id: editingNoteId,
      note: editingNoteText,
    });
    if (result.error) {
      showToast('error', result.error.data.msg || 'Failed to update note');
    } else {
      showToast('success', 'Note updated successfully');
      setEditingNoteId(null);
      setEditingNoteText('');
    }
    refetch();
    handleClose();
  };

  useEffect(() => {
    if (addJobNotesInfo.isSuccess || updateJobNotesInfo.isSuccess) {
      showToast('success', 'Notes Saved Successfully');
      refetch();
    }
    if (addJobNotesInfo.isError || updateJobNotesInfo.isError) {
      showToast('error', addJobNotesInfo.error?.data?.msg || updateJobNotesInfo.error?.data?.msg || 'An error occurred');
    }
    if (deleteJobNotesinfo.isSuccess) {
      showToast('success', deleteJobNotesinfo.data.msg);
      refetch();
      setNoteText('');
    }
    if (deleteJobNotesinfo.isError) {
      showToast('error', deleteJobNotesinfo.error?.data?.msg || 'An error occurred');
      refetch();
    }
  }, [addJobNotesInfo, updateJobNotesInfo, deleteJobNotesinfo, refetch]);

  function formatDateRange(createdDateTimeString) {
    const createdDateTime = new Date(createdDateTimeString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = createdDateTime.toLocaleDateString("en-US", options);
    const timeOptions = { hour: "numeric", minute: "numeric", second: "numeric"};
    const formattedTime = createdDateTime.toLocaleTimeString("en-US", timeOptions);
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'note', headerName: 'Note', width: 600 },
    { field: 'created', headerName: 'Created', width: 500 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      renderCell: (params) => (
        <>
          <Button variant="contained" onClick={() => onEditHandler(params.row)} sx={{ mr: 1 }}>Edit</Button>
          <Button variant="contained" onClick={() => handleDialogOpen(params.row.id)}>Delete</Button>
        </>
      ),
    },
  ];

  const rows = jobNotesData?.notes?.map((note) => ({
    id: note.id,
    note: note.note,
    created: formatDateRange(note.created),
  })) || [];

  return (
    <>
    <Grid container style={{ display: 'flex', alignItems: 'center' }}>
        <Grid  item md={10} sx={{ mt: 1, mb: 2 }}>
            <h3 className=''>Notes</h3>
        </Grid>
        <Grid item md={2} style={{ textAlign: 'center' }}>
            <Button
            onClick={handleClickOpen}
            variant="contained"
            style={{ textTransform: 'capitalize' }}
            >
            Add Note
            </Button>
        </Grid>
    </Grid>
    <Divider />
      {/* <h4 id='education' className='canhead'>Notes</h4>
      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <Grid item>
          <Button variant="contained" onClick={handleClickOpen}>
            Add Note
          </Button>
        </Grid>
      </Grid> */}
      <Grid container sx={{ mt: 4 }}>
        <Grid item md={12}>
          <div style={{ height: 300, width: '100%' }}>
            <AutoSizer>
              {({ height, width }) => (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={3}
                  rowsPerPageOptions={[3]}
                  disableSelectionOnClick
                  autoHeight
                  style={{ height, width }}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? classes.evenRow : classes.oddRow
                }
                />
              )}
            </AutoSizer>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md" // Set the maximum width to medium
        fullWidth // Ensure the dialog takes the full width
        sx={{ '& .MuiDialog-paper': { minHeight: '300px', minWidth: '400px' } }} // Customize the size
      >
        <DialogTitle>{isEditMode ? 'Edit Note' : 'Add Note'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isEditMode ? 'Edit your note below:' : 'Add a new note below:'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="Note"
            type="text"
            fullWidth
            variant="outlined"
            value={isEditMode ? editingNoteText : noteText}
            onChange={(e) => isEditMode ? setEditingNoteText(e.target.value) : setNoteText(e.target.value)}
          />
          {!isEditMode && (
          <FormControlLabel
            control={
              <Checkbox
                checked={addToCandidates}
                onChange={(e) => setAddToCandidates(e.target.checked)}
                name="addToCandidates"
                color="primary"
              />
            }
            label="Add this note to the candidate record"
            sx={{ mt: 2 }}
          />
        )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={isEditMode ? saveEditHandler : addNotesHandler}>
            {isEditMode ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }} // Adjust the height as needed
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobNotes;