import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AppBar, Box, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, InputLabel, List, ListItem, ListItemText, Menu, MenuItem, TextField, Toolbar, Tooltip } from '@mui/material';
import ReactQuill from 'react-quill';
import { showToast } from "../../../utils/toast";
import { useDeleteInterviewMutation, useGetInterviewDetailsQuery } from "../../../redux/services/interview/InterviewServices"
import { useGetEmailTamplateQuery } from '../../../redux/services/settings/EmailTamplateService';
import { useGetLocationQuery } from '../../../redux/services/settings/LocationService';
import Iconify from '../../../components/Iconify';


const PriviewInterview = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isPriviewId = Boolean(id);

  const { data: interviewData, refetch: interviewDataRefetch } = useGetInterviewDetailsQuery(id, { skip: !isPriviewId });
  const { data: emailTemplateData, refetch: emailTemplateDataRefetch } = useGetEmailTamplateQuery()
  const { data: locationData, refetch: locationDataRefetch } = useGetLocationQuery()
  const [deleteInterview, deleteInterviewInfo] = useDeleteInterviewMutation();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [anchorElActions, setAnchorElActions] = React.useState(null);

  useEffect(() => {
    emailTemplateDataRefetch();
  },[]);

  const getEmailTemplateName = (templateId) => {
    const template = emailTemplateData?.data?.find(template => template.id === templateId);
    return template ? template.subject : '';
  };

  const getLocationName = (locationId) => {
    const location = locationData?.data?.find(location => location.id === locationId);
    return location ? location.name : '';
  };

  const handleDeleteJob = async () => {
    try {
      await deleteInterview(id);
      showToast("success", "Interview deleted successfully!");
      navigate('/dashboard/interviews');
    } catch (error) {
        showToast("error", "Failed to delete Interview!");
    }
  };
  
    const handleDeleteClickOpen = () => {
      setOpenDeleteModel(true);
    };
  
    const handleDeleteClose = () => {
      setOpenDeleteModel(false);
    };

  const actionItems = [
    { label: 'Edit Interview', action: () => navigate(`/dashboard/interviews/edit-interview/${id}`) },
    // { label: 'Associate candidate', action: assignJobModel },
    // { label: 'Schedule interview', action: handleCreateInterview },
    // { label: 'Clone job',  action: handleCloneJob },
    { label: 'Delete', action: handleDeleteClickOpen },
  ];

  const handleOpenActionsMenu = (event) => {
    setAnchorElActions(event.currentTarget);
  };
  const handleCloseActionsMenu = () => {
    setAnchorElActions(null);
  };

  useEffect(() => {
    if (isPriviewId) {
      interviewDataRefetch();
    }
  }, [id, isPriviewId, interviewDataRefetch]);

  return (
    <div>
      <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#f9fafb' }}>
        <Toolbar>
        <IconButton
              edge="start"
              color="secondary"
              aria-label="close"
            >
              <ArrowBackIosIcon onClick={() => (navigate(-1))} />
            </IconButton>
            
          <Grid container spacing={3}  alignItems="center">
            {/* <Grid item md={10} style={{ display: 'flex', alignItems: 'center' }}>
              <h2 style={{
                color: "black",
                width: "400px",
                marginRight: "50px"
              }}>
                {interviewData?.data?.title || ''}
              </h2>
            </Grid> */}
            <Grid item md={10}>
              <Typography variant="h5" sx={{ color: 'black', marginRight: 2, backgroundColor: 'transparent !important'}}>
                {interviewData?.data?.title || ''}
              </Typography>
            </Grid>
            {/* <Grid item md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleOpenActionsMenu}
                    size="small"
                    sx={{ ml: 1 }}
                    // aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    // aria-expanded={open ? 'true' : undefined}
                  >
                    <Iconify icon="entypo:dots-three-vertical" width={24} height={24} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorElActions}
                open={Boolean(anchorElActions)}
                onClose={handleCloseActionsMenu}
              >
                {actionItems.map((item) => (
                  <MenuItem key={item.label} onClick={item.action}>
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </Grid> */}
            <Grid item md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleOpenActionsMenu}
                  size="small"
                  sx={{ ml: 1 }}
                  aria-haspopup="true"
                >
                  <Iconify icon="entypo:dots-three-vertical" width={24} height={24} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorElActions}
                open={Boolean(anchorElActions)}
                onClose={handleCloseActionsMenu}
              >
                {actionItems.map((item) => (
                  <MenuItem key={item.label} onClick={item.action}>
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
  
        </Toolbar>
      </AppBar>
    
      <Divider variant="middle" />
      <Grid container sx={{ mt: 1 }} spacing={2}>
        {/* <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={() => navigate(`/dashboard/interviews/edit-interview/${id}`)}
            sx={{ textTransform: 'capitalize' }}
          >
            Edit Interview
          </Button>
        </Grid> */}
        {/* <Grid item xs={12} sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                label="Name"
                value={interviewData?.data?.title || ''}
                InputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Select Template"
                value={getEmailTemplateName(interviewData?.data?.email_temp) || ''}
                InputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Select Candidate"
                value={`${interviewData?.data?.candidate?.first_name || ''} ${interviewData?.data?.candidate?.last_name || ''}`}
                InputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Select Job"
                value={interviewData?.data?.job?.title || ''}
                InputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Type"
                value={interviewData?.data?.type || ''}
                InputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Subject"
                value={interviewData?.data?.email_sub || ''}
                InputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Date"
                value={interviewData?.data?.date || ''}
                InputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputLabel>Email Body</InputLabel>
              <ReactQuill value={`${interviewData?.data?.email_msg}`} theme={"snow"} readOnly formats={[]} modules={{ toolbar: false }} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Start Time"
                    value={interviewData?.data?.time_start || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="End Time"
                    value={interviewData?.data?.time_end || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Location"
                    value={getLocationName(interviewData?.data?.location) || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Interviewers"
                    value={interviewData?.data?.interviewers || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      
      {/* <Grid container sx={{ mt: 1 }} spacing={2}> */}
        <Grid item md={12} sx={{ ml: 0 }}>
          <Card sx={{ ml: 0, mr: 2, p: 2 }}>
            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
              <Grid  item md={10} sx={{ mt: 1, mb: 2 }}>
                <h3 id='education' className=''>Interview Information</h3>
              </Grid>
              <Grid item md={2} style={{ textAlign: 'center' }}>
                <Button
                  component={RouterLink}
                  to= {`/dashboard/interviews/edit-interview/${id}`}
                  variant="contained"
                  style={{ textTransform: 'capitalize' }}
                >
                   Edit Interview
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ mt: 4 }} spacing={2}>
              <Grid item md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>Title:</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1}}>
                        {interviewData?.data?.title || ''}
                      </Box>
                    <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>Candidate Name:</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {`${interviewData?.data?.candidate?.first_name || ''} ${interviewData?.data?.candidate?.last_name || ''}`}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>Posting Title:</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.job?.title || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>Interview Type:</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.type || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>Email Subject:</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.email_sub || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>Start Time:</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                      {interviewData?.data?.time_start || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>Date:</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.date || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} sx={{ mb: 2 }}>    
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>End Time:</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                      {interviewData?.data?.time_end || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>Location:</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {getLocationName(interviewData?.data?.location) || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4}>
                      <strong style={{ textAlign: 'right' }}>Interviewer(s):</strong>
                    </Grid>
                    <Grid item md={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.interviewers?.length > 0 ? (
                          <List>
                            {interviewData.data.interviewers.map(interviewer => (
                              <ListItem key={interviewer.id}>
                                <ListItemText
                                  primary={interviewer.name}
                                  secondary={`${interviewer.email} • ${interviewer.mobile}`}
                                />
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          'No interviewers assigned'
                        )}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={11.5} sx={{ mb: 2 }}>
                <strong style={{ textAlign: 'center' }}>Email Body</strong>
                {/* <InputLabel title='Job Description'>Job Description</InputLabel> */}
                <div
                  dangerouslySetInnerHTML={{ __html: interviewData?.data?.email_msg || '' }}
                  style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '4px' }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={openDeleteModel}
        onClose={handleDeleteClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ '& .MuiDialog-paper': { height: '200px' } }}
      >
        <DialogTitle>{'Delete'}</DialogTitle>
        <DialogContent>
        <DialogContentText>Are you sure you want to delete this Interview?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Disagree</Button>
          <Button onClick={handleDeleteJob} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PriviewInterview;