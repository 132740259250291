import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import ImagePreview from '../../../components/imagePreview/ImagePreview';
import { useGetCountryQuery, useGetStateQuery, useGetCityQuery } from '../../../redux/services/settings/CountryStateCityService';
import { useAddCompanyInfoMutation } from '../../../redux/services/settings/CareerSiteService';
import { showToast } from '../../../utils/toast';

function AddCompany() {
  const [countryId, setCountryId] = useState(skipToken);
  const [stateId, setStateId] = useState(skipToken);
  const { data: stateData, refetchState } = useGetStateQuery(countryId);
  const { data: countryData, refetch } = useGetCountryQuery();
  const { data: cityData, refetch: refectCity } = useGetCityQuery(stateId);
  const [addCompany, addCompanyInfo] = useAddCompanyInfoMutation();
  const [companyData, setCompanyData] = useState({})
  const navigate = useNavigate()

  const onInputChangeHandler = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    if (e.target.name === "country") {
      setCountryId(e.target.value)
      setCompanyData({ ...companyData, [e.target.name]: e.target.value });
      refetchState()
      //   setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
    }
    if (e.target.name === "state") {
      setStateId(e.target.value)
      setCompanyData({ ...companyData, [e.target.name]: e.target.value });
      refectCity()
      //   setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
    }
    setCompanyData({ ...companyData, [e.target.name]: e.target.value });
  };

  const companyLogoChangeHandler = async (file) => {
    setCompanyData({ ...companyData, logo: file });
  }

  const onSubmit = async() => {
    const auth = JSON.parse(localStorage.getItem("globalUser"))
    const formData = new FormData();
    formData.append('admin', auth.account.account_id);
    formData.append('company', companyData.name);
    formData.append('domain', companyData.domain);
    formData.append('website', companyData.link);
    formData.append('address', companyData.address);
    formData.append('phone', companyData.phone);
    formData.append('email', companyData.email);
    formData.append('landmark', companyData.landmark);
    formData.append('city', companyData.city);
    formData.append('pincode', companyData.pincode);
    formData.append('logo', companyData.logo);
    formData.append('description', companyData.description);
    // formData.append('tag', companyData.tag);
    await addCompany(formData);
  }

  useEffect(()=>{
    if (addCompanyInfo.isSuccess){
      toast.success('Company added successfully');
      navigate("/dashboard/InstituteSettings/settings")
    }
    if (addCompanyInfo.isError){
      toast.error(`${addCompanyInfo.error.data.msg}`);
    }

  },[addCompanyInfo])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={6}>
          <TextField

            margin="dense"
            variant="outlined"
            fullWidth
            name="name"
            // value={companyData.company}
            label="Company Name"
            onChange={onInputChangeHandler}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField

            margin="dense"
            variant="outlined"
            fullWidth
            name="domain"
            // value={companyData.company}
            label="Company domain"
            onChange={onInputChangeHandler}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField

            margin="dense"
            variant="outlined"
            fullWidth
            name="link"
            // value={companyData.company}
            label="Website Link"
            onChange={onInputChangeHandler}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField

            margin="dense"
            variant="outlined"
            fullWidth
            name="address"
            // value={companyData.company}
            label="Address"
            onChange={onInputChangeHandler}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField

            margin="dense"
            variant="outlined"
            fullWidth
            name="phone"
            // value={companyData.company}
            label="Phone"
            onChange={onInputChangeHandler}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField

            margin="dense"
            variant="outlined"
            fullWidth
            name="email"
            // value={companyData.company}
            label="Email"
            onChange={onInputChangeHandler}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField

            margin="dense"
            variant="outlined"
            fullWidth
            name="pincode"
            // value={companyData.company}
            label="Pincode"
            onChange={onInputChangeHandler}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField

            margin="dense"
            variant="outlined"
            fullWidth
            name="landmark"
            // value={companyData.company}
            label="Landmark"
            onChange={onInputChangeHandler}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <InputLabel id="select-country">Select country</InputLabel>
            <Select
              labelId="select-country"
              id="country"
              name="country"
              //   value={fieldValue.country}
              onChange={onInputChangeHandler}
              label="Select country"
            >
              {countryData && countryData?.countries?.map((country) => <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <InputLabel id="select-state">State</InputLabel>
            <Select
              labelId="select-state"
              id="state"
              name="state"
              //   value={fieldValue.state}
              onChange={onInputChangeHandler}
              label="State"
            >
              {stateData ? stateData?.states?.map((state) => <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>) : <MenuItem value="">
                <em>None</em>
              </MenuItem>}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <InputLabel id="select-city">select City</InputLabel>
            <Select
              labelId="select-city"
              id="city"
              name="city"
              //   value={fieldValue.city}
              onChange={onInputChangeHandler}
              label="Select City"
            >
              {cityData ? cityData?.cities?.map((city) => <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>) : <MenuItem value="">
                <em>None</em>
              </MenuItem>}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <ImagePreview
              name='logo'
              // logo={companyData.logo}
              onFileSelectSuccess={(file, e) => companyLogoChangeHandler(file)}
              onFileSelectError={({ error }) => showToast("error", error)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            multiline
            rows={10}
            margin="dense"
            variant="outlined"
            fullWidth
            // value={companyData.description}
            label="About the Institute"
            name="description"
            onChange={onInputChangeHandler}
          />
        </Grid>
        <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant='contained' onClick={onSubmit}>Add Company</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default AddCompany