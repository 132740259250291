// // instruments.js (or any other suitable name)
// export const Education = [
//   {
//     instruments: [
//       'HIGH_SCHOOL',
//       'JUNIOR_COLLEGE',
//       'BACHELORS',
//       'MASTERS',
//     ],
//   },
// ].map(({ label, instruments }) => ({
//   options: instruments.map((s) => ({ name: s, label: s })),
// }));

// export const Type = [
//   {
//     type: [
//       'FULL_TIME',
//       'PART_TIME',
//     ],
//   },
// ].map(({ label, type }) => ({
//   options: type.map((s) => ({ name: s, label: s })),
// }));

// export const JobNature = [
//   {
//     nature: [
//       'Physical',
//       'Remote',
//     ],
//   },
// ].map(({ label, nature }) => ({
//   options: nature.map((s) => ({ name: s, label: s })),
// }));

// export const SalaryType = [
//   {
//     salary: [
//       'MONTHLY',
//       'YEARLY',
//       'WEKLY',
//       'DAILY',
//     ],
//   },
// ].map(({ label, salary }) => ({
//   options: salary.map((s) => ({ name: s, label: s })),
// }));

// export const Currency = [
//   {
//     currency: [
//       'US',
//       'INR',
//     ],
//   },
// ].map(({ label, currency }) => ({
//   options: currency.map((s) => ({ name: s, label: s })),
// }));

export const Education = [
  'HIGH_SCHOOL',
  'JUNIOR_COLLEGE',
  'BACHELORS',
  'MASTERS',
].map((s) => ({ name: s, label: s }));

export const Type = [
  'FULL_TIME',
  'PART_TIME',
].map((s) => ({ name: s, label: s }));

export const JobNature = [
  'Physical',
  'Remote',
].map((s) => ({ name: s, label: s }));

export const SalaryType = [
  'MONTHLY',
  'YEARLY',
  'WEEKLY',
  'DAILY',
].map((s) => ({ name: s, label: s }));

export const Currency = [
  'US',
  'INR',
].map((s) => ({ name: s, label: s }));
