import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewer = ({ open, onClose, pdfUrl }) => {

const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    if (pdfUrl) {
      fetch(pdfUrl, { mode: 'no-cors' })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error('Error fetching PDF:', error);
        });
    }
  }, [pdfUrl]);

return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
            PDF Viewer
            <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <div style={{ height: '500px' }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
              <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
          </div>
        </DialogContent>
    </Dialog>
);
};

export default PDFViewer;