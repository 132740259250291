import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    filterAssesment: build.mutation({
      query: (queryString) => ({
        url: `${apiUrl.assesment}?${queryString}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useFilterAssesmentMutation,
} = extendedApi;
