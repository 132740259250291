import { createSlice } from '@reduxjs/toolkit';
import initialState from './State';

export const jobReducer = createSlice({
  name: 'job',
  initialState,
  reducers: {
    jobAction: (state, action) => {
      state.job = { ...state.job, ...action.payload };
    },
    setJobAddressData: (state, action) => {
      state.jobAddressData = action.payload;
    },
    setJobOwner: (state, action) => {
      state.jobGetuserData = action.payload;
    },
  },
});

export const { jobAction,setJobAddressData,setJobOwner } = jobReducer.actions;

export default jobReducer.reducer;
