import React from "react";
import SendMail from "../../components/individualCandidate/sendMail/SendMail";

const Candidates = () => {
  return (
    <div>
      <SendMail />
    </div>
  );
};

export default Candidates;
