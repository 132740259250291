import React, { useEffect } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { Button, Card, CardContent, Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useResponsive from '../hooks/useResponsive';
import { useActivateVerifyUserMutation } from '../redux/services/user/userService'; // Import the mutation hook
import { showToast } from '../utils/toast';
import Page from '../components/Page';
import Logo from '../components/Logo';

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));
  
const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));


function ActivateAccount() {

    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');

    const { token } = useParams();
    const navigate = useNavigate();
    const [activateVerifyUser, { isLoading, isSuccess, isError, error }] = useActivateVerifyUserMutation();
    
    // useEffect(() => {
    //     const activateAccount = async () => {
    //     try {
    //         await activateVerifyUser({ token }).unwrap();
    //         showToast('success', 'Account activated successfully');
    //     } catch (err) {
    //         showToast('error', 'Activation failed. Please try again.');
    //     }
    //     };

    //     if (token) {
    //         activateAccount();
    //     }
    // }, [token, activateVerifyUser]);

    if (isLoading) return <div>Activating your account...</div>;
    if (isError) return <div>Error: {error.message}</div>;

    return (

    <Page title="Login">
        <RootStyle>
            <HeaderStyle>
            <Logo />
        
            </HeaderStyle>

            {mdUp && (
            <SectionStyle>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                Hi, Welcome Back
                </Typography>
                <img src="/static/illustrations/illustration_login.png" alt="login" />
            </SectionStyle>
            )}

            <Container maxWidth="sm">
                <ContentStyle>
                    <Card style={{ maxWidth: 400, margin: 'auto', marginTop: '20%', textAlign: 'center' }}>
                        <CardContent>
                            {isSuccess ? (
                                <>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Done!
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        You’ve activated your account and you’re now ready to use it!
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: 20 }}
                                        onClick={() => navigate('/login')}
                                    >
                                        GO TO Login
                                    </Button>
                                </>
                            ) : (
                                <Typography variant="h5" component="div">
                                    Activating your account...
                                </Typography>
                            )}
                        </CardContent>
                    </Card>

                    {!smUp && (
                    <p style={{textAlign:"center", marginTop: "3%" }}>
                        Don’t have an account?{' '}
                        <Link variant="subtitle2" component={RouterLink} to="/register">
                        Get started
                        </Link>
                    </p>
                    )}
                </ContentStyle>
            </Container>
        </RootStyle>
    </Page>
        

        // <Card style={{ maxWidth: 400, margin: 'auto', marginTop: '20%', textAlign: 'center' }}>
        //     <CardContent>
        //         {isSuccess ? (
        //             <>
        //                 <Typography variant="h5" component="div" gutterBottom>
        //                     Done!
        //                 </Typography>
        //                 <Typography variant="body2" color="textSecondary">
        //                     You’ve activated your account and you’re now ready to use it!
        //                 </Typography>
        //                 <Button
        //                     variant="contained"
        //                     color="primary"
        //                     style={{ marginTop: 20 }}
        //                     onClick={() => navigate('/dashboard')}
        //                 >
        //                     GO TO DASHBOARD
        //                 </Button>
        //             </>
        //         ) : (
        //             <Typography variant="h5" component="div">
        //                 Activating your account...
        //             </Typography>
        //         )}
        //     </CardContent>
        // </Card>
    );
}

export default ActivateAccount;
