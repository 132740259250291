import * as Yup from 'yup';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  Stack,
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormLabel,
  Typography,
  Card,
  Grid,
  Divider
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ReactQuill from 'react-quill';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import dayjs from 'dayjs';
// eslint-disable-next-line import/no-unresolved
import { showToast } from 'src/utils/toast';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { skipToken } from '@reduxjs/toolkit/dist/query';
// eslint-disable-next-line import/no-unresolved
import { useGetInterviewerDetailsQuery } from 'src/redux/services/settings/interviewContact';
import FileUploadComponent from '../../../components/FileUploadComponent';
import { useGetJobListQuery } from "../../../redux/services/jobs/JobListService";
import { useGetEmailTamplateQuery, useGetEmailTemplateByIdQuery } from '../../../redux/services/settings/EmailTamplateService';
import { useGetUsersApiQuery } from '../../../redux/services/settings/UserService';
import { useGetLocationQuery } from "../../../redux/services/settings/LocationService";
import { useGetCandidateListQuery } from '../../../redux/services/candidate/CandidateServices';
import { useAddInterviewMutation, useGetInterviewDetailsQuery, useUpdateInterviewMutation } from "../../../redux/services/interview/InterviewServices"
import Back from "../../../assets/images/back.svg"


function NewCreateInterview(props) {

  const location = useLocation ();

  const { jobsId, job, candidate} = location.state || {};

  const { id } = useParams();
  const isEdit = Boolean(id);
  const today = new Date();
  const navigate = useNavigate();
  const [addInterview, addInterviewInfo] = useAddInterviewMutation();
  const [updateInterview, updateInterviewInfo] = useUpdateInterviewMutation();
  const { data: interviewData, refetch: interviewDataRefetch } = useGetInterviewDetailsQuery(id, { skip: !isEdit });
  const label = { inputProps: { 'aria-label': 'Mode' } };
  const [value, setValue] = useState(dayjs(Date.now()));
  const handleChange = (e) => setValue(e)
  const [startTime, setStartTime] = useState(dayjs(Date.now()));
  const [endTime, setEndTime] = useState(dayjs(Date.now()));
  const [emailTemplateId, setEmailTemplateId] = useState(skipToken);
  const { data: emailTemplateDetails, refetch: emailTemplateDetailsRefetch } = useGetEmailTemplateByIdQuery(emailTemplateId);
  const handleChangeStartTime = (e) => {
    setStartTime(e);
    const date = dayjs(e);
    handleChangeFormData("time_start", `${date.get("hour")}:${date.get("minute")}:00`);
  };
  
  const handleChangeEndTime = (e) => {
    setEndTime(e);
    const date = dayjs(e);
    handleChangeFormData("time_end", `${date.get("hour")}:${date.get("minute")}:00`);
  };

  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ]
  };

  const formats = [
    'font',
    'size',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'align',
    'color', 'background'
  ];

  // const handleChangeStartTime = (e) => {
  //   setStartTime(e);
  //   const date = dayjs(e);
  //   handleChangeFormData("time_start", `${date.get("hour")}:${date.get("minute")}:00`);
  // };
  
  // const handleChangeEndTime = (e) => {
  //   setEndTime(e);
  //   const date = dayjs(e);
  //   handleChangeFormData("time_end", `${date.get("hour")}:${date.get("minute")}:00`);
  // };

  const state = {
    comments: ''
  }

  const { data: jobData, refetch: jobDataRefetch } = useGetJobListQuery()
  const { data: emailTemplateData, refetch: emailTemplateDataRefetch } = useGetEmailTamplateQuery()
  const { data: candidateData, refetch: candidateDataRefetch } = useGetCandidateListQuery()
  const { data: locationData, refetch: locationDataRefetch } = useGetLocationQuery()
  const { data: interviewerData, refetch: interviewerDataRefetch } = useGetInterviewerDetailsQuery()
  // console.log("locationData......",locationData);
  useEffect(() => {
    interviewerDataRefetch();
    jobDataRefetch();
    emailTemplateDataRefetch();
    candidateDataRefetch();
    locationDataRefetch();
  },[]);

  const [formData, setFormData] = useState({
    candidate_id: "",
    job_id: "",
    date: `${value.get("year")}-${String(value.get("month") + 1).padStart(2, 0)}-${String(value.get("date")).padStart(2, 0)}`,
    time_start: `${startTime.get("hour")}:${startTime.get("minutes")}:00`,
    time_end: `${endTime.get("hour")}:${endTime.get("minutes")}::00`,
    location_id: "",
    type: "",
    interviewers: [],
    email_temp_id: "",
    email_sub: "",
    email_msg: "",
    title: "",
  })

  useEffect(() => {
    if (isEdit && interviewData) {
      const interview = interviewData.data;
      // console.log("interview.......",interview);
      setFormData({
        candidate_id: interview.candidate.id,
        job_id: interview.job.id,
        date: interview.date,
        time_start: interview.time_start,
        time_end: interview.time_end,
        location_id: interview.location,
        type: interview.type,
        interviewers: interview.interviewers,
        email_temp_id: interview.email_temp,
        email_sub: interview.email_sub,
        email_msg: interview.email_msg,
        title: interview.title,
      });
      setValue(dayjs(interview.date, "YYYY-MM-DD"));
      setStartTime(dayjs(interview.time_start, "HH:mm:ss"));
      setEndTime(dayjs(interview.time_end, "HH:mm:ss"));
    }
  }, [isEdit, interviewData]);
  
  useEffect(() => {
    if (!formData.job_id && jobsId) {
      setFormData(prev => ({
        ...prev,
        job_id: jobsId 
      }));
    }
  }, [jobsId, formData.job_id]);

  useEffect(() => {
    if (job || candidate) {
      setFormData(prev => ({
        ...prev,
        job_id: job?.id || "",
        candidate_id: candidate?.id || ""
      }));
    }
  }, [job, candidate]);

  const handleChangeFormData = (name, value) => {
    if(name === "email_temp_id"){
      setEmailTemplateId(value)
    }
    if (name === "interviewers") {
      setFormData(prev => ({
        ...prev,
        interviewers: Array.isArray(value) ? value : [value],
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value !== null && value !== undefined ? value : prev[name]
      }));
    }
  };

  const stripHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const handleSubmit = async () => {
    if (isValidateInterview()) {

      const emailTemplate = stripHtmlTags(formData.email_msg) || '';
      const placeholders = emailTemplate.match(/\${(.*?)}/g)?.map(ph => ph.replace(/\${|}/g, '').trim()) || [];
    
      const payload = {
        ...formData,
        placeholders,
      };

      if (isEdit) {
        await updateInterview({ id, ...formData });
      } else {
        await addInterview(payload);
      }
    }
  };

  useEffect(() => {
    if (emailTemplateId !== skipToken) {
      emailTemplateDetailsRefetch();
      setFormData(prev => ({
        ...prev,
        email_sub: emailTemplateDetails?.subject,
        email_msg: emailTemplateDetails?.message
      }));
    }
  }, [emailTemplateId, emailTemplateDetails, emailTemplateDetailsRefetch]);

  useEffect(() => {
    if (addInterviewInfo.isSuccess || updateInterviewInfo.isSuccess) {
      showToast("success", isEdit ? "Interview updated successfully" : "Interview added successfully");
      navigate("/dashboard/interviews", { replace: true });
    }
    if (addInterviewInfo.isError || updateInterviewInfo.isError) {
      showToast("error", addInterviewInfo.error?.data?.msg || updateInterviewInfo.error?.data?.msg || "Please fill all the details");
    }
  }, [addInterviewInfo, updateInterviewInfo, navigate]);

  const isValidateInterview = () => {
    let status = true;
    const now = dayjs();

    if (!formData.title) {
      status = false;
      showToast('error', 'Fill Title');
    }
    if (!formData.candidate_id) {
      status = false;
      showToast('error', 'Select Candidate');
    }
    if (!formData.date) {
      status = false;
      showToast('error', 'Select Date');
    }
    if (!formData.time_start) {
      status = false;
      showToast('error', 'Select Start Time');
    }
    if (!formData.time_end) {
      status = false;
      showToast('error', 'Select End Time');
    }
    if (!formData.location_id) {
      status = false;
      showToast('error', 'Select Location');
    }
    if (!formData.email_temp_id) {
      status = false;
      showToast('error', 'Select Email Template');
    }
    if (dayjs(formData.date).isBefore(now, 'day') || 
        (dayjs(formData.date).isSame(now, 'day') && dayjs(formData.time_start, 'HH:mm:ss').isBefore(now))) {
      status = false;
      showToast('error', 'Start time cannot be in the past');
    }
    if (dayjs(formData.date).isSame(now, 'day') && dayjs(formData.time_end, 'HH:mm:ss').isBefore(now)) {
      status = false;
      showToast('error', 'End time cannot be in the past');
    }
    if (dayjs(formData.time_end, 'HH:mm:ss').isBefore(dayjs(formData.time_start, 'HH:mm:ss'))) {
      status = false;
      showToast('error', 'End time must be after start time');
    }
    return status;
  };


  const stackStyles = {
    height: '280px', // set the desired height here
  };

  const fieldStyles = {
    width: '90%',
    maxWidth: '600px',
    margin: '0 auto'
  };

  return (
    <div>
      
      <Stack sx={{ display: "flex", flexDirection: "row", gap: "5%" }} ml={5} mb={5}>
        <Link to={-1}>
          <img src={Back} alt="Go Back" />
        </Link>
        <Typography 
          variant="h4" 
          color="textPrimary" 
          sx={{ 
            width: '300px',
            textDecorationLine: 'underline',
            backgroundColor: 'transparent !important'
          }}
        >
          {isEdit ? "Edit Interview" : "Create Interview"}
        </Typography>
      </Stack>
      <Card sx={{ padding: '30px' }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={5}>
            <Stack spacing={2} alignItems="center">
              <TextField
                sx={fieldStyles}
                required
                id="standard-required"
                label="Title"
                variant="standard"
                name="title"
                value={formData.title}
                onChange={(e) => handleChangeFormData(e.target.name, e.target.value)}
              />
              <FormControl variant="standard" sx={fieldStyles}>
                <InputLabel id="select-candidate">Select Candidate</InputLabel>
                <Select
                  required
                  id="standard-required"
                  label="Candidate"
                  name="candidate_id"
                  value={formData.candidate_id}
                  onChange={(e) => handleChangeFormData(e.target.name, e.target.value)}
                >
                  {candidateData && candidateData.list ? candidateData.list.map((candidate) => (
                    <MenuItem key={candidate.id} value={candidate.id}>
                      {candidate.first_name} {candidate.last_name}
                    </MenuItem>
                  )) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormLabel>Interview Type</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="type"
                value={formData.type}
                onChange={(e) => handleChangeFormData(e.target.name, e.target.value)}
              >
                <Stack direction="row" spacing={2}>
                  <FormControlLabel value="IP" control={<Radio />} label="In person" />
                  <FormControlLabel value="PC" control={<Radio />} label="Telephonic" />
                  <FormControlLabel value="VC" control={<Radio />} label="Video" />
                </Stack>
              </RadioGroup>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date"
                  required
                  disablePast
                  inputFormat="YYYY-MM-DD"
                  name="date"
                  minDate={today}
                  value={value}
                  onChange={(e) => {
                    handleChange(e);
                    const date = dayjs(e);
                    handleChangeFormData("date", `${date.get("year")}-${String(date.get("month") + 1).padStart(2, 0)}-${String(date.get("date")).padStart(2, 0)}`);
                  }}
                  renderInput={(params) => <TextField {...params} sx={fieldStyles} />}
                />
                <TimePicker
                  required
                  disablePast
                  label="Start Time"
                  value={startTime}
                  onChange={e => {
                    handleChangeStartTime(e);
                    const date = dayjs(e);
                    handleChangeFormData("time_start", `${date.get("hour")}:${date.get("minutes")}:00`);
                  }}
                  renderInput={(params) => <TextField {...params} sx={fieldStyles} />}
                />
                <TimePicker
                  required
                  label="End Time"
                  value={endTime}
                  onChange={e => {
                    handleChangeEndTime(e);
                    const date = dayjs(e);
                    handleChangeFormData("time_end", `${date.get("hour")}:${date.get("minutes")}:00`);
                  }}
                  renderInput={(params) => <TextField {...params} sx={fieldStyles} />}
                />
              </LocalizationProvider>
              <FormControl variant="standard" sx={fieldStyles}>
                <InputLabel id="select-location">Select Location</InputLabel>
                <Select
                  required
                  id="standard-required"
                  label="Location"
                  name="location_id"
                  value={formData.location_id}
                  onChange={(e) => handleChangeFormData(e.target.name, +e.target.value)}
                >
                  {locationData ? locationData?.data?.map((e) =>
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>) : <MenuItem value="">
                    <em>None</em>
                  </MenuItem>}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={fieldStyles}>
                <InputLabel id="select-interviewer">Select Contact for Interviewer</InputLabel>
                <Select
                  required
                  id="standard-required"
                  label="Interviewers"
                  name="interviewers"
                  multiple
                  value={formData.interviewers}
                  onChange={(e) => handleChangeFormData(e.target.name, e.target.value)}
                >
                  {interviewerData?.data?.map((e) =>
                    <MenuItem key={e?.id} value={e?.id}>
                      {e.name} • {e?.mobile}
                    </MenuItem>)}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Divider orientation="vertical" flexItem  sx={{ borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px' }}/>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack spacing={2} alignItems="center">
              <FormControl variant="standard" sx={fieldStyles}>
                <InputLabel id="select-email-template">Select Email Template</InputLabel>
                <Select
                  required
                  id="standard-required"
                  label="Select Email Template"
                  name="email_temp_id"
                  value={formData.email_temp_id}
                  onChange={(e) => handleChangeFormData(e.target.name, +e.target.value)}
                >
                  {emailTemplateData?.data?.map((e) =>
                    <MenuItem key={e.id} value={e.id}>
                      {e.subject}
                    </MenuItem>)}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={fieldStyles}>
                <InputLabel id="select-job">Select Job</InputLabel>
                <Select
                  required
                  id="standard-required"
                  label="Job"
                  name="job_id"
                  value={formData.job_id}
                  onChange={(e) => handleChangeFormData(e.target.name, +e.target.value)}
                >
                  {jobData ? jobData?.map((e) =>
                    <MenuItem key={e.id} value={e.id}>
                      {e.title}
                    </MenuItem>) : <MenuItem value="">
                    <em>None</em>
                  </MenuItem>}
                </Select>
              </FormControl>
              <TextField
                sx={fieldStyles}
                required
                id="standard-required"
                label="Subject"
                variant="standard"
                name="email_sub"
                value={formData.email_sub}
                onChange={e => handleChangeFormData(e.target.name, e.target.value)}
              />
              <Typography>Email Body</Typography>
              <ReactQuill
                // style={stackStyles}
                style={{ ...stackStyles, ...fieldStyles }}
                theme="snow"
                modules={modules}
                formats={formats}
                value={formData.email_msg}
                name="email_msg"
                onChange={e => handleChangeFormData("email_msg", e)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <Stack justifyContent="center" alignItems='center' pt={4}>
        {/* <hr style={{ width: '90%', color: 'grey', marginBottom: '5%' }} /> */}
        <Button variant="contained" onClick={handleSubmit}>Schedule</Button>
      </Stack>
    </div>
  )
}

export default NewCreateInterview