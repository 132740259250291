import React from 'react';
import AddFormJobs from './AddFormJobs';


function ToDoAppJobs({ jobId }) {
    return (
        <div>
            <AddFormJobs
            jobId={jobId}
            />
        </div>
    )
}

export default ToDoAppJobs