import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';

const AddNewContact = (props) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { open, handleClose, textboxlabel, loadingbtn, formstagedata, onsubmit, type, companyName, data } = props;
  const [formData, setFormData] = useState({
    id: data?.id || null,
    name: data?.name || '',
    email: data?.email || '',
    mobile: data?.mobile || '',
  });

  const handleChange = (name, value) => {
    if (name === 'mobile') {
      if (value.length === 10) {
        setErrorMessage('');
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
      else {
        setErrorMessage('Please enter a 10-digit phone number.');
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    if (data) {
      setFormData({
        id: data?.id,
        name: data?.name,
        email: data?.email,
        mobile: data?.mobile,
      });
    } else {
      setFormData({
        id: null,
        name: '',
        email: '',
        mobile: '',
      });
    }
  }, [data]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="alertmodalCloseHandler-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.5)' } }}
      >
        <div>
          <DialogTitle>{textboxlabel || 'Update Contact'}</DialogTitle>
          <DialogContent>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <TextField
                    id="Add Contact"
                    label={data ? 'Edit Contact Name' : 'Add Contact Name'}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange('name', e.target.value)}
                    value={formData?.name}
                  />
                  <TextField
                    sx={{
                      marginTop: '20px',
                    }}
                    id="Add Contact"
                    label={data ? 'Edit Contact Email' : 'Add Contact Email'}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange('email', e.target.value)}
                    value={formData?.email}
                  />
                  <TextField
                    sx={{
                      marginTop: '20px',
                    }}
                    id="phone"
                    label={data ? 'Edit Contact Phone number' : 'Add Contact Phone number'}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange('mobile', e.target.value)}
                    value={formData?.mobile}
                    error={errorMessage !== ''}
                    helperText={errorMessage}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ position: 'relative' }}>
            <Box>
              <Button onClick={handleClose} autoFocus variant="outlined" style={{ marginRight: 5 }}>
                Cancel
              </Button>

              <LoadingButton
                onClick={() => onsubmit(formData)}
                variant="contained"
                loading={loadingbtn}
                // disabled={error}
              >
                {formData.id ? 'Update' : 'Add'}
              </LoadingButton>
            </Box>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default AddNewContact;
