import React from "react";
import ScheduleInterview from "../../components/individualCandidate/scheduleInterview/ScheduleInterview";

const ScheduleInterviewPage = () => {
  return (
    <div>
      <ScheduleInterview />
    </div>
  );
};

export default ScheduleInterviewPage;
