import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';



const extendedApi = apiBasePath.injectEndpoints({
    endpoints: (build) => ({
        getCompanyInfo: build.query({
            query: () => ({
                url: `${apiUrl.companyInfo}`
            }),
        }),
        updateCompanyInfo: build.mutation({
            query: (data) => ({
                url: `${apiUrl.companyInfo}`,
                method: "POST",
                body: data,
            })
        }),
        updateCompanyLogo: build.mutation({
            query: (data) => ({
                url: `${apiUrl.companyLogo}`,
                method: "POST",
                body: data,
            })
        }),
        getCompanyTags: build.query({
            query: () => ({
                url: apiUrl.companyTags
            })
        }),
        addCompanyInfo: build.mutation({
            query: (data) => ({
                url: `${apiUrl.companyAdd}`,
                method: "POST",
                body: data,
            })
        }),
        getPerticularCompanyInfo: build.query({
            query: (companyId) => ({
                url: `${apiUrl.companyAdd}?id=${companyId}`
            }),
        }),
    }),
    overrideExisting: false,

});

export const { useGetCompanyInfoQuery, useUpdateCompanyInfoMutation, useUpdateCompanyLogoMutation, useGetCompanyTagsQuery,useAddCompanyInfoMutation,useGetPerticularCompanyInfoQuery } = extendedApi;
