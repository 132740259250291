import React, { useState, useMemo, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  ListItemIcon,
  DialogContent,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  useGetEmailTamplateQuery,
  useGetEmailVariableTamplateQuery,
  useDeleteEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
} from '../../../redux/services/settings/EmailTamplateService';
import { useGetEmailCategoryQuery } from '../../../redux/services/settings/EmailCategoryService';
import { sortedDataFn } from '../../../utils/getSortedData';
import { showToast } from '../../../utils/toast';

// components
import EmailModalTemplates from '../../../components/email-templates/EmailModalTemplates';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
// mock

const Templates = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const { data = [], isLoading, refetch } = useGetEmailTamplateQuery();
  useEffect(() => refetch(), []);
  const { data: categoryData, isLoading: isCategoryLoading } = useGetEmailCategoryQuery();
  const { data: variableData, isLoading: isVariableLoading } = useGetEmailVariableTamplateQuery();
  const [DeleteEmailTemplate, DeleteEmailTemplateInfo] = useDeleteEmailTemplateMutation();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalName, setModalName] = useState('add');

  const [editValue, setEditValue] = useState();

  const modalHandleClose = (value) => {
    setModalOpen(value);
    setEditModalOpen(value);
    refetch();
  };

  // Show Data In Table

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [active, setActive] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleeClosee = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setActive(false);
  };
  const handleClosee = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const sortData = useMemo(() => {
    const sortresult = sortedDataFn(data.data);
    return sortresult;
  });

  const navigateCancel = () => {
    navigate('/dashboard/InstituteSettings/settings');
  };

  const addNewTemplatesHandler = () => {
    setModalOpen(true);
  };

  const onEditModalHandler = (dataIndex) => {
    setEditModalOpen(true);
    const dataArr = sortData;
    const currentDataObj = dataArr[dataIndex];
    setEditValue(currentDataObj);
    setModalName('Edit');
  };

  /// delete email template
  const onEmailTemplateDeleteHandler = async (dataIndex) => {
    setOpen(false);
    setActive(true);
    setCurrentIndex(dataIndex);
    const dataArr = sortData;
    const currentDataObj = dataArr[dataIndex];
    await DeleteEmailTemplate(currentDataObj.id);
  };
  useEffect(() => {
    if (DeleteEmailTemplateInfo.isSuccess) {
      showToast('success', DeleteEmailTemplateInfo.data.msg);
      DeleteEmailTemplateInfo.reset();
      refetch();
    }
    if (DeleteEmailTemplateInfo.isError) {
      showToast('error', DeleteEmailTemplateInfo.error.data.msg);
      DeleteEmailTemplateInfo.reset();
      refetch();
    }
  }, [DeleteEmailTemplateInfo, refetch, categoryData]);

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'category_name',
      label: 'Category Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { maxWidth: '250px' } }),
      },
    },
    {
      name: 'subject',
      label: 'Subject',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: 'message',
    //   label: 'Message',
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: 'action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => (
          <>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleeClosee}
              sx={{ height: '10vh' }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                position: 'absolute',
              }}
            >
              <Alert onClose={handleeClosee} severity="success" sx={{ width: '100%' }}>
                Interview Deleted!
              </Alert>
            </Snackbar>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClosee}
                aria-labelledby="responsive-dialog-title"
                sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }}
              >
                <DialogTitle variant="h4">{'Delete'}</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you wan&#39;t to delete this template?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClosee}>
                    Disagree
                  </Button>
                  <Button
                    onClick={() => onEmailTemplateDeleteHandler(dataIndex)}
                    loading={dataIndex === currentIndex ? useDeleteEmailTemplateMutation.isLoading : false}
                    autoFocus
                  >
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Button
              style={{ minWidth: 0 }}
              onClick={() => navigate(`/dashboard/updateEmail/${sortData[dataIndex]?.id}`)}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="ep:edit" width={24} height={24} color={'blue'} />
              </ListItemIcon>
            </Button>
            <LoadingButton
              style={{ minWidth: 0, margin: '0px 5px' }}
              // variant="contained"
              color="error"
              onClick={handleClickOpen}
              // onClick={() => onEmailTemplateDeleteHandler(dataIndex)}
              loading={dataIndex === currentIndex ? useDeleteEmailTemplateMutation.isLoading : false}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} color={'red'} />
              </ListItemIcon>
            </LoadingButton>
          </>
        ),
      },
    },
  ];
  const labelStatus = (
    <Label variant="ghost" color={'success'}>
      {sentenceCase('active')}
    </Label>
  );
  const editAndDeleteButton = (
    <>
      <Button onClick={onEditModalHandler}>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:edit-fill" width={24} height={24} />
        </ListItemIcon>
      </Button>
      <Button>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:trash-2-outline" width={24} height={24} />
        </ListItemIcon>
      </Button>
    </>
  );
  const options = {
    filterType: 'dropdown',
  };

  const getInputValue = (value) => {
    console.log('value', value);
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="backbutton tt-back" style={{ display: 'flex' }}>
              <ArrowBackIosIcon
                onClick={navigateCancel}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </div>
            {/* <h1>Templates</h1> */}
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Templates
            </Typography>
          </Container>
          <Button
            style={{ width: '14%', height: '46px' }}
            variant="contained"
            component={RouterLink}
            to="/dashboard/createEmail"
            // onClick={addNewTemplatesHandler}
            startIcon={
              <Iconify
                icon="eva:plus-fill"
                sx={{
                  height: '35px',
                }}
              />
            }
          >
            Create Template
          </Button>
        </Stack>

        <Card>
          <MUIDataTable title={'Templates List'} data={sortData} columns={columns} options={options} />
        </Card>
      </Container>
      <EmailModalTemplates
        open={modalOpen}
        handleClose={modalHandleClose}
        label="Add List"
        type="text"
        textBoxLabel="List Name"
        id="listName"
        name="list"
        autocomplete="off"
        getInputValue={getInputValue}
        buttonLabel="Add List"
        categoryData={categoryData?.data}
        variableData={variableData?.data}
      />
      <EmailModalTemplates
        open={editmodalOpen}
        handleClose={modalHandleClose}
        label="Edit List"
        type="text"
        textBoxLabel="List Name"
        id="editListName"
        name="list"
        getInputValue={getInputValue}
        buttonLabel="Update List"
        categoryData={categoryData?.data}
        variableData={variableData?.data}
        emailTemplateData={editValue}
      />
    </Page>
  );
};

export default Templates;
