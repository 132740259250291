// instruments.js (or any other suitable name)
export const HighestQualification = [
  {
    qualifications: [
      'Secondary_School',
      'High_School',
      'Diploma',
      'Post_Graduate_Diploma',
      'Graduate',
      'Post_Graduate',
      'Doctorate',
    ],
  },
].map(({ label, qualifications }) => ({
  options: qualifications.map((s) => ({ name: s, label: s })),
}));

export const ProfessionalDegreeOptions = [
  'B.Ed.',
  'D.Ed.',
  'Diploma',
  'B.E',
  'B.Arch',
  'MBBS',
  'Other',
].map(s => ({ name: s, label: s }))


export const Boards = [
  {
    label: 'Boards',
    boardsOptions: [
      'ICSE',
      'CBSE',
      'STATE_BOARD',
      'IGCSE',
      'IB',
      'OTHER',
      'NONE',
    ],
  },
].map(({ boardsOptions }) => ({
  options: boardsOptions.map((s) => ({ name: s, label: s })),
}));


export const FunctionalArea = [
  {
    label: 'Functional Area',
    areas: [
      'Teaching',
      'Administer',
      'Manager',
      'head_of_department',
      'Coordinator',
      'OTHER',
    ],
  },
].map(({ areas }) => ({
  options: areas.map((s) => ({ name: s, label: s })),
}));


export const NoticePeriod = [
  'Immediate joining',
  '15_days',
  '1_month',
  '2_months',
  '3_months',
  '4_months',
  '5_months',
  '6_months',
].map((s) => ({ name: s, label: s }));


export const State = [
  {
    states: [
      'California',
      'Texas',
      'New York',
    ],
  },
].map(({ label, states }) => ({
  options: states.map((s) => ({ name: s, label: s })),
}));

export const Country = [
  {
    country: [
      'USA',
      'Canada',
      'India',
    ],
  },
].map(({ label, country }) => ({
  options: country.map((s) => ({ name: s, label: s })),
}));

export const City = [
  {
    citys: [
      'Los Angeles',
      'San Francisco',
    ],
  },
].map(({ label, citys }) => ({
  options: citys.map((s) => ({ name: s, label: s })),
}));

