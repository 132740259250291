import React from 'react';
import JobBoards from '../../../settings/job-boards/JobBoards';


const SelectJobBoards = () => (
    <>
    <JobBoards/>
  
    
    </>
  );
export default SelectJobBoards;
