export const fieldDefinitions = [
    {
      section: 'Personal Details',
      fields: [
        { name: 'First Name', type: 'Text', required: true },
        { name: 'Middle Name', type: 'Text', required: false },
        { name: 'Last Name', type: 'Text', required: true },
        { name: 'Email', type: 'Email', required: true },
        { name: 'Alternative Email', type: 'Email', required: false },
        { name: 'Mobile Number', type: 'Phone', required: true },
        { name: 'Alternative Mobile Number', type: 'Phone', required: false },
        { name: 'Date of Birth', type: 'Date', required: true },
        { name: 'Age', type: 'Number', required: true },
        { name: 'Gender', type: 'Dropdown', required: true, values: ['Male', 'Female', 'Other'] },
        { name: 'Marital Status', type: 'Dropdown', required: true, values: ['Married', 'Single', 'Divorced', 'Widow'] },
      ],
    },
    {
      section: 'Professional Details',
      fields: [
        { name: 'Years of Experience', type: 'Number', required: true },
        { name: 'Highest Qualification held', type: 'Dropdown', required: true, values: ['Secondary School', 'High School', 'Diploma', 'Post Graduate Diploma', 'Graduate', 'Post Graduate', 'Doctorate'] },
        { name: 'Current Employer', type: 'Text', required: true },
        { name: 'Current Job Title', type: 'Text', required: true },
        { name: 'Professional Degree', type: 'Dropdown', required: true, values: ['B.Ed.', 'D.Ed.', 'Diploma', 'B.E', 'B.Arch', 'MBBS', 'Other'] },
        { name: 'Curriculum/Board', type: 'Dropdown', required: true, values: ['ICSE', 'CBSE', 'STATE BOARD', 'IGCSE', 'IB', 'OTHER', 'NONE'] },
        { name: 'Functional Area', type: 'Dropdown', required: true, values: ['Teaching', 'Administer', 'Manager', 'Head of Department', 'Coordinator'] },
        { name: 'Notice Period', type: 'Dropdown', required: true, values: ['Immediate joining', '15 days', '1 Month', '2 Month', '3 Month', '4 Month', '5 Month', '6 Month'] },
      ],
    },
    {
      section: 'Address Info',
      fields: [
        { name: 'Street', type: 'Text', required: true },
        { name: 'City', type: 'Text', required: true },
        { name: 'State', type: 'Text', required: true },
        { name: 'Country', type: 'Text', required: true },
        { name: 'Pincode', type: 'Number', required: true },
      ],
    },
  ];