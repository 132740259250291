import React from 'react';
import { Stack, Box, Grid, Typography, Divider } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import vector from "../../../assets/images/backward.png";

function AssessmentPreview() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, form, type, categoryName, description } = location.state;
  return (
    <div className='preview-container' style={{ padding: '20px 60px' }}>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Box onClick={() => navigate(-1)} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', padding: '10px', '&:hover': { cursor: 'pointer', backgroundColor: '#D9D9D9', borderRadius: '10px', transition: 'background-color 0.3s ease' } }}>
          <img style={{ marginBottom: '6px' }} src={vector} alt="" width='11px' height='19px' />
          <Stack sx={{ fontSize: '22px', fontWeight: '700', color: '#000', paddingLeft: '30px' }}>Preview</Stack>
        </Box>
      </Stack>
      <Box sx={{ background: 'white', borderRadius: '15px', padding: '20px',  width: '60%', margin: '0 auto' }}>
        <Grid container spacing={2}>
            <Grid item md={12} sx={{ mb: 2 }}>
              <Typography variant="body1">
                <Grid container>
                  <Grid item md={2}>
                    <strong style={{ textAlign: 'right' }}>Type:</strong>
                  </Grid>
                  <Grid item md={4}>
                    <Box component="span" sx={{ ml: 1 }}>
                      {name || '-'}
                    </Box>
                  <Divider flexItem />
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item md={12} sx={{ mb: 2 }}>
              <Typography variant="body1">
                <Grid container>
                  <Grid item md={2}>
                    <strong style={{ textAlign: 'right' }}>Category:</strong>
                  </Grid>
                  <Grid item md={4}>
                    <Box component="span" sx={{ ml: 1 }}>
                      {categoryName || '-'}
                    </Box>
                  <Divider flexItem />
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          
          {/* <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Description:</strong> {description || '-'}</Typography>
          </Grid> */}
      </Grid>
        <Box sx={{ marginTop: '20px', borderTop: '1px solid #E0E0E0' }}>
          <Typography variant="h5" sx={{ fontWeight: '700', marginTop: '20px' }}>{name} <span style={{ fontWeight: '250' }}>[{form.length}]</span></Typography>
          <Box sx={{ marginTop: '10px' }}>
            {form?.map((question, index) => (
              <Box key={index} sx={{ padding: '10px 0'}}>
                <Typography variant="body1" sx={{ fontWeight: '600' }}>{`${index + 1}. ${question.question}`} {question.optional && <span style={{ color: 'red' }}>*</span>}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default AssessmentPreview;