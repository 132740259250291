/* eslint-disable camelcase */
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Stack, TextField, IconButton, InputAdornment, Divider, Select, MenuItem, Card, Button, Grid, FormControl, InputLabel } from '@mui/material';

import { useGetAllCompaniesQuery } from '../../redux/services/settings/CountryStateCityService';
import { useDepartmentGetQuery } from '../../redux/services/settings/DepartmentService';
import { useDesignationGetQuery } from '../../redux/services/settings/DesignationService';
import { useAddUserMutation } from "../../redux/services/user/userService"

import { showToast } from '../../utils/toast';
import Iconify from '../Iconify';


function AddUser() {
  const [showPassword, setShowPassword] = useState(false);
  const account_id = useSelector((state) => state.login.account_id);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [AddUser, AddUserInfo] = useAddUserMutation();
  const { data: departmentData, refetch: departmentDataRefetch } = useDepartmentGetQuery();
  useEffect(() => {
    departmentDataRefetch()
  }, [departmentData])

  const { data: designationData, refetch: designationDataRefetch } = useDesignationGetQuery();
  useEffect(() => {
    designationDataRefetch()
  }, [designationData])

  const { data: allcompanyData, refetch: allcompanyDataRefetch } = useGetAllCompaniesQuery();
  useEffect(() => {
    allcompanyDataRefetch()
  }, [allcompanyData])

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is required"),
    username: Yup.string().email('Email must be a valid email address').required('Email is required'),
    companyName: Yup.string().required("Company Name is required").min(5, "Too Short!"),
    address: Yup.string().required("Address is required").min(10, "Too Short!"),
    landmark: Yup.string().required("Address is required").min(5, "Too Short!"),
    city: Yup.string().required("Address is required"),
    pincode: Yup.string().matches(/^[1-9][0-9]{5}$/, "Pincode is invalid").required("Pincode is required"),
    password: Yup.string().required("Password is required").min(8, "Too Short")
  });

  const navigate = useNavigate()
  const navigatecancel = () => {
    navigate('/dashboard/users/list')
  }
  // const proceed = () =>{
  //   navigate('/dashboard/user/adduser/createpassword')
  // }


  const validate = (values) => {
    const errors = {}

    if (!values.username) {
      errors.username = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
      errors.username = 'Invalid email address'
    }
    if (!values.first_name) {
      errors.first_name = 'Required'
    }

    if (!values.last_name) {
      errors.last_name = 'Required'
    }
    if (!values.designation) {
      errors.designation = 'Required'
    }
    if (!values.role) {
      errors.role = 'Required'
    }

    if (!values.department) {
      errors.department = 'Required'
    }
    if (!values.mobile) {
      errors.mobile = 'Required'
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.confirmpassword) {
      errors.confirmpassword = 'Required';
    }
    if (!values.company_id) {
      errors.company_id = 'Required';
    }

    return errors
  }
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      department: 0,
      designation: 0,
      role: "",
      username: "",
      mobile: "",
      password: "",
      company_id:"",
      confirmpassword: "",
    },
    validate,
    onSubmit: async (values) => {
      AddUser(values)
    },
  });
  useEffect(() => {
    if (AddUserInfo.isSuccess) {
      showToast("success", "Success User Created")
      navigate('/dashboard/users/list');
    }
    if (AddUserInfo.isError) {
      const errorData = AddUserInfo.error?.data || {};
      const mobileError = errorData.mobile?.[0];
      const usernameError = errorData.username?.[0];

    if (mobileError) {
      showToast("error", "Mobile already exists");
    } else if (usernameError) {
      showToast("error", "Email already exists");
    } else {
      showToast("error", "Please fill all details");
    }
  }
  })
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setSubmitting } = formik;


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card sx={{
          position: "relative",
          marginLeft: "auto",
          marginRight: "auto",
          width: "90%",
          backgroundColor: "#fff",
          boxShadow: '0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%)',
          borderRadius: '16px',
          padding: '20px'
        }}>
          <div className="backbutton tt-back">
            <ArrowBackIosIcon onClick={navigatecancel} sx={{ cursor: "pointer" }} />
          </div>
          <Stack sx={{
            marginTop: "1%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "2%"
          }}>
            <h1 className='dialogueTitle'>Create User Profile</h1>
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First name"
                {...getFieldProps('first_name')}
                error={Boolean(touched.first_name && errors.first_name)}
                helperText={touched.first_name && errors.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last name"
                {...getFieldProps('last_name')}
                error={Boolean(touched.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                {...getFieldProps('username')}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Mobile"
                {...getFieldProps('mobile')}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={Boolean(touched.department && errors.department)}>
                <InputLabel>Department</InputLabel>
                <Select
                  label="Department"
                  {...getFieldProps('department')}
                >
                  <MenuItem value={0}>Select Department</MenuItem>
                  {departmentData?.data?.map((e, i) => (
                    <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
                  ))}
                </Select>
                {touched.department && errors.department && <div>{errors.department}</div>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={Boolean(touched.designation && errors.designation)}>
                <InputLabel>Designation</InputLabel>
                <Select
                  label="Designation"
                  {...getFieldProps('designation')}
                >
                  <MenuItem value={0}>Select Designation</MenuItem>
                  {designationData?.data?.map((e, i) => (
                    <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
                  ))}
                </Select>
                {touched.designation && errors.designation && <div>{errors.designation}</div>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
                <InputLabel>Role</InputLabel>
                <Select
                  label="Role"
                  {...getFieldProps('role')}
                >
                  <MenuItem value={""}>Select Role</MenuItem>
                  <MenuItem value={"A"}>Admin</MenuItem>
                  <MenuItem value={"U"}>User</MenuItem>
                </Select>
                {touched.role && errors.role && <div>{errors.role}</div>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={Boolean(touched.company_id && errors.company_id)}>
                <InputLabel>Company</InputLabel>
                <Select
                  label="Company"
                  {...getFieldProps('company_id')}
                >
                  <MenuItem value={0}>Select Company</MenuItem>
                  {allcompanyData?.companies?.map((e, i) => (
                    <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
                  ))}
                </Select>
                {touched.company_id && errors.company_id && <div>{errors.company_id}</div>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirm Password"
                {...getFieldProps('confirmpassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowConfirmPassword((prev) => !prev)}>
                        <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                helperText={touched.confirmpassword && errors.confirmpassword}
              />
            </Grid>
            <Grid item xs={12}>
              <div className='divrowcb'>
                <input
                  className="inutbarcb"
                  id="status"
                  name="status"
                  type="checkbox"
                  checked
                />
                <label className="cblabel" htmlFor='Status'>is Active
                  {formik.touched.status && formik.errors.status ? <div>{formik.errors.role}</div> : null}
                </label>
              </div>
            </Grid>
            <Grid container justifyContent="center" spacing={2} sx={{ marginTop: "2%" }}>
              <Grid item xs={12} sm={3} sx={{ marginRight: "2rem" }}>
                <Button
                  type='submit'
                  variant="contained"
                  fullWidth
                  sx={{ marginBottom: "2%"}}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  type='button'
                  variant="contained"
                  onClick={navigatecancel}
                  fullWidth
                  sx={{ marginBottom: "2%" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Form>
    </FormikProvider>
  )
}

export default AddUser


