import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Modal from '../../../components/modal/Modal';
import { showToast } from '../../../utils/toast';
import Editable from '../../../components/webform/WebformEditable';
import EditableSection from '../../../components/webform/WebformEditableSection';
import {
  useAddWebformFieldsMutation,
  useGetWebformDetailsQuery,
  useUpdateWebformFieldsMutation,
} from '../../../redux/services/settings/FieldServices';
import { fieldDefinitions } from './fieldDefinitions';

export default function CreateWebform() {
  const { id } = useParams();
  const { data: webformData, refetch: webformDataRefetch } = useGetWebformDetailsQuery(id);
  useEffect(() => {
    webformDataRefetch();
  }, [webformData]);
  const navigate = useNavigate();
  const [addWebfromFields, addWebformFieldsInfo] = useAddWebformFieldsMutation();
  const [updateWebformFields, updateWebformFieldsInfo] = useUpdateWebformFieldsMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const handleChangeModalData = (name, value) =>
    setModalData((prev) => {
      prev[name] = value;
      return prev;
    });
  const [formTitle, setFormTitle] = useState(webformData?.data?.name || 'New Form');
  const handleChangeFormTitle = (e) => setFormTitle(e.target.value);
  const [formData, setFormData] = useState(webformData?.data?.form || fieldDefinitions);
  const resetForm = () => {
    setFormData(webformData?.data?.form || fieldDefinitions);
    setFormTitle(webformData?.data?.name || 'New Form');
  };
  const [sections, setSections] = useState(formData.map((e) => e.name));
  const addSection = (name, fields) => {
    setFormData((prev) => {
      if (!sections.includes(name)) {
        setSections((pre) => [...pre, name]);
        return [...prev, { name, fields }];
      }
      showToast('error', 'Section with the same name already exists.');
      return prev;
    });
  };
  const updateSectionFields = (sectionName, fieldName, fields) => {
    if (sections.includes(sectionName)) {
      setFormData((prev) => {
        prev[sections.indexOf(sectionName)][fieldName] = fields;
        setSections(prev.map((e) => e.name));
        return prev;
      });
    }
  };
  const navigateCancel = () => {
    navigate('/dashboard/candidate-settings/webforms');
  };
  const deleteSection = (sectionName) => {
    if (sections.includes(sectionName)) {
      if (sections.length === 1) {
        showToast('error', 'At least one section is required.');
        return;
      }
      setFormData((prev) => {
        prev.splice(sections.indexOf(sectionName), 1);
        setSections(prev.map((e) => e.name));
        return prev;
      });
      return;
    }
    showToast('error', 'Section with the name does not exist.');
  };
  const handleSubmit = async () => {
    if (!id) {
      await addWebfromFields({
        name: formTitle.trim(),
        form: formData,
      });
    } else {
      await updateWebformFields({
        id,
        name: formTitle.trim(),
        form: formData,
      });
    }
  };
  useEffect(() => {
    if (addWebformFieldsInfo.isError) {
      showToast(
        'error',
        addWebformFieldsInfo.error.message || addWebformFieldsInfo.error.error || 'Failed adding webform'
      );
      console.log(addWebformFieldsInfo.error);
    }
    if (addWebformFieldsInfo.isSuccess) {
      showToast('success', 'Successfully added webform');
      resetForm();
      navigate('/dashboard/candidate-settings/webforms', { replace: true });
    }
  }, [addWebformFieldsInfo, navigate]);

  useEffect(() => {
    if (updateWebformFieldsInfo.isError) {
      showToast(
        'error',
        updateWebformFieldsInfo.error.message || updateWebformFieldsInfo.error.error || 'Failed updating webform'
      );
      console.log(updateWebformFieldsInfo.error);
    }
    if (updateWebformFieldsInfo.isSuccess) {
      showToast('success', 'Successfully updated webform');
      resetForm();
      navigate('/dashboard/candidate-settings/webforms', { replace: true });
    }
  }, [updateWebformFieldsInfo, navigate]);

  return (
    <Container sx={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div className="backbutton tt-back" style={{ display: 'flex' }}>
            <ArrowBackIosIcon
              onClick={navigateCancel}
              sx={{
                cursor: 'pointer',
              }}
            />
          </div>
          <h1>{id ? 'Update' : 'Create'} Webform</h1>
        </Container>
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1rem',
          }}
        >
          <Button variant="text" color="error" onClick={() => navigate('/dashboard/candidate-settings/webforms')}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setModalOpen(true);
              setModalData({
                name: '',
                fields: [
                  {
                    name: '',
                    type: '',
                  },
                ],
              });
            }}
          >
            Add Section
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Container>
      </Stack>
      <Container sx={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <h2>{formTitle}</h2>
          </Container>
          <Editable value={formTitle} handleChange={handleChangeFormTitle} placeholder="Edit Form Title" fullWidth />
        </Stack>
        {formData.map((section, i) => (
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} key={i}>
            <Container
              sx={{
                backgroundColor: 'white',
                borderRadius: '1rem',
                padding: '2rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
                border: '1px solid black',
              }}
            >
              <Editable
                value={section.name}
                name="name"
                handleChange={(event) => updateSectionFields(section.name, event.target.name, event.target.value)}
                placeholder="Edit Section Name"
                deletable={sections.length > 1}
                handleDelete={() => deleteSection(section.name)}
                fullWidth
              >
                <Container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <h2>{section.name}</h2>
                </Container>
              </Editable>

              <Container sx={{ display: 'flex', borderRadius: '1rem', flexDirection: 'column', gap: '2rem' }}>
                {section.fields.map((field, j) => (
                  <Container key={j} sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <EditableSection
                      value={field.name}
                      placeholder={field.name}
                      handleChange={(event) => {
                        const { fields, name } = section;
                        fields[j].name = event.target.value.trim();
                        updateSectionFields(name, field.name, fields);
                      }}
                      handleChangeRequired={(event) => {
                        const { fields, name } = section;
                        fields[j].required = event.target.checked;
                        updateSectionFields(name, field.name, fields);
                      }}
                      deletable
                      handleDelete={() => {
                        const { fields, name } = section;
                        fields.splice(j, 1);
                        updateSectionFields(name, 'fields', fields);
                      }}
                      types={['Text', 'Paragraph', 'Number', 'Phone', 'Email', 'Dropdown', 'Date']}
                      currentType={field.type}
                      handleChangeType={(event) => {
                        const { fields, name } = section;
                        fields[j].type = event.target.value;
                        updateSectionFields(name, 'fields', fields);
                      }}
                      elementType={field.type}
                      dropdownValues={field.values}
                      elementRequired={field.required}
                    >
                      <TextField placeholder={field.name} variant="filled" label={field.name} disabled />
                      <TextField placeholder={field.name} variant="filled" label={field.type} disabled />
                      <FormControlLabel
                        control={<Switch defaultChecked={field.required} />}
                        label="Required"
                        disabled
                        labelPlacement="start"
                      />
                    </EditableSection>
                  </Container>
                ))}
              </Container>

              <Button
                variant="text"
                onClick={() => {
                  setModalOpen(true);
                  setModalData({});
                }}
              >
                Add Field
              </Button>
            </Container>
          </Stack>
        ))}
      </Container>
      <Modal
        handleClose={() => {
          setModalOpen(false);
          setModalData({});
        }}
        open={modalOpen}
        handleSubmit={() => {
          addSection(modalData.name, modalData.fields);
          setModalData({});
          setModalOpen(false);
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5rem',
          }}
        >
          <Typography variant="h4">Create Section</Typography>
          <Button
            variant="contained"
            onClick={() => {
              if (!Object.keys(modalData).includes('fields')) {
                handleChangeModalData('fields', []);
              }
              setModalData((prev) => ({
                ...prev,
                fields: [
                  ...prev.fields,
                  {
                    name: '',
                    type: '',
                  },
                ],
              }));
            }}
          >
            Add Field
          </Button>
        </Container>
        <TextField
          name="name"
          onChange={(e) => {
            handleChangeModalData(e.target.name, e.target.value);
          }}
          label="Section Name"
          placeholder="Section Name"
          required
        />
        {modalData.fields && modalData.fields.length && <Divider flexItem>Fields</Divider>}
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          {modalData.fields?.map((e, i) => (
            <Container
              key={i}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2rem',
                justifyContent: 'space-between',
              }}
            >
              <TextField
                name="fields"
                onChange={(event) => {
                  const { fields } = modalData;
                  fields[i].name = event.target.value.trim();
                  setModalData((prev) => ({ ...prev, fields }));
                }}
                label="Field Name"
                placeholder="Field Name"
                required
              />
              <TextField
                name="fields"
                onChange={(event) => {
                  const { fields } = modalData;
                  fields[i].type = event.target.value;
                  setModalData((prev) => ({ ...prev, fields }));
                }}
                label="Field Type"
                placeholder="Field Type"
                select
                SelectProps={{
                  native: true,
                  defaultValue: '',
                }}
                required
              >
                {['Text', 'Paragraph', 'Number', 'Phone', 'Email', 'Dropdown', 'Date'].map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </TextField>
            </Container>
          ))}
        </Container>
      </Modal>
    </Container>
  );
}