import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getCandidateNotesList: build.query({
      query: (candidateId) => ({
        url: `${apiUrl.notes}?candidate=${candidateId}`,
      }),
    }),
    getJobNotesList: build.query({
      query: (jobId) => ({
        url: `${apiUrl.job_notes}?job=${jobId}`,
      }),
    }),
    getNotesTypes: build.query({
      query: () => ({
        url: `${apiUrl.notesType}`,
      }),
    }),
    addCandidateNotes: build.mutation({
      query: (data) => ({
        url: `${apiUrl.notes}`,
        method: 'POST',
        body: data,
      }),
    }),
    addJobNotes: build.mutation({
      query: (data) => ({
        url: `${apiUrl.job_notes}`,
        method: 'POST',
        body: data,
      }),
    }),

    updateCandidateNotes: build.mutation({
      query: (data) => ({
        url: `${apiUrl.notes}`,
        method: 'PUT',
        body: data,
      }),
    }),
    updateJobNotes: build.mutation({
      query: (data) => ({
        url: `${apiUrl.job_notes}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteCandidateNotes: build.mutation({
      query: (id) => ({
        url: `${apiUrl.notes}?id=${id}`,
        method: 'DELETE',
      }),
    }),
    deleteJobNotes: build.mutation({
      query: (id) => ({
        url: `${apiUrl.job_notes}?id=${id}`,
        method: 'DELETE',
      }),
    }),
    addCandidateTasks: build.mutation({
      query: (data) => ({
        url: `${apiUrl.tasks}`,
        method: 'POST',
        body: data,
      }),
    }),
    updateCandidateTasks: build.mutation({
      query: ({ id, data }) => ({
        url: `${apiUrl.tasks}${id}/`,
        method: 'PUT',
        body: data,
      }),
    }),
    getCandidateTaksList: build.query({
      query: (id) => ({
        url: `${apiUrl.tasksList}${id}/`,
      }),
    }),
    deleteCandidateTasks: build.mutation({
      query: (id) => ({
        url: `${apiUrl.tasks}${id}/`,
        method: 'DELETE',
      }),
    }),
    getTaskOptionsList: build.query({
      query: () => ({
        url: `${apiUrl.taskOptionsList}`,
      }),
    }),
    addTasks: build.mutation({
      query: (data) => ({
        url: `${apiUrl.addTasks}`,
        method: 'POST',
        body: data,
      }),
    }),
    getTaskList: build.query({
      query: (id) => ({
        url: `${apiUrl.addTasks}${id}/`,
      }),
    }),
    updateTasks: build.mutation({
      query: ({ id, data }) => ({
        url: `${apiUrl.addTasks}${id}/`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteTasks: build.mutation({
      query: (id) => ({
        url: `${apiUrl.addTasks}${id}/`,
        method: 'DELETE',
      }),
    }),
    completeTasks: build.mutation({
      query: (id) => ({
        url: `${apiUrl.addTasks}${id}/complete/`,
        method: 'POST',
      }),
    }),
    getEventOptionsList: build.query({
      query: () => ({
        url: `${apiUrl.eventOptionsList}`,
      }),
    }),
    addEvents: build.mutation({
      query: (data) => ({
        url: `${apiUrl.events}`,
        method: 'POST',
        body: data,
      }),
    }),
    getEventList: build.query({
      query: (id) => ({
        url: `${apiUrl.events}${id}/`,
      }),
    }),
    updateEvents: build.mutation({
      query: ({ id, data }) => ({
        url: `${apiUrl.events}${id}/`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteEvents: build.mutation({
      query: (id) => ({
        url: `${apiUrl.events}${id}/`,
        method: 'DELETE',
      }),
    }),
    getCallOptionsList: build.query({
      query: () => ({
        url: `${apiUrl.callOptionsList}`,
      }),
    }),
    addCalls: build.mutation({
      query: (data) => ({
        url: `${apiUrl.calls}`,
        method: 'POST',
        body: data,
      }),
    }),
    getCallList: build.query({
      query: (id) => ({
        url: `${apiUrl.calls}${id}/`,
      }),
    }),
    updateCalls: build.mutation({
      query: ({ id, data }) => ({
        url: `${apiUrl.calls}${id}/`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteCalls: build.mutation({
      query: (id) => ({
        url: `${apiUrl.calls}${id}/`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCandidateNotesListQuery,
  useGetJobNotesListQuery,
  useGetNotesTypesQuery,
  useAddCandidateNotesMutation,
  useAddJobNotesMutation,
  useUpdateCandidateNotesMutation,
  useUpdateJobNotesMutation,
  useDeleteCandidateNotesMutation,
  useDeleteJobNotesMutation,
  useAddCandidateTasksMutation,
  useUpdateCandidateTasksMutation,
  useGetCandidateTaksListQuery,
  useDeleteCandidateTasksMutation,
  useGetTaskOptionsListQuery,
  useAddTasksMutation,
  useGetTaskListQuery,
  useUpdateTasksMutation,
  useDeleteTasksMutation,
  useCompleteTasksMutation,
  useGetEventOptionsListQuery,
  useAddEventsMutation,
  useGetEventListQuery,
  useUpdateEventsMutation,
  useDeleteEventsMutation,
  useGetCallOptionsListQuery,
  useAddCallsMutation,
  useGetCallListQuery,
  useUpdateCallsMutation,
  useDeleteCallsMutation,
} = extendedApi;
