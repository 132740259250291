import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Radio, FormControlLabel, Checkbox, Stack } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { Container } from '@mui/system';

const AssesmentModal = (props) => {
    const { open, handleClose, data } = props;

    const calculateTotalMarks = (question) => {
        console.log("form getting", question)
        let totalMarks = 0;
        const arr1 = question?.answers;
        const arr2 = question?.candidateAnswer
        arr2?.forEach((element, index) => {
            if (index !== 0) {
                totalMarks += arr1[parseInt(element - 1, 10)]
            }
        });
        return totalMarks
    }

    return (
       
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Assesment Response</DialogTitle>
                <DialogContent
                    sx={{
                        // width: "600px", 
                        padding: "0 1rem",
                        paddingTop: "2rem",
                        paddingBottom: "6rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                        // backgroundColor: "#828282"
                    }}
                >
                    <Stack sx={{
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}>
                                            {data?.[0]?.form?.length > 0 ? (
                        data[0].form.map((e, i) => (
                            <Container key={i}>
                                {/* {e?.eval>=e?.marks ? "- Qualified":"- Unqualified"} */}
                                {e.type === "T" && (
                                    <Container sx={{ color: `${e?.candidateAnswer ? "green" : "red"}` }}>
                                        {i + 1}{") "}Q.{e.question}  - Marks Obtained {"=>"} {e?.candidateAnswer} {e?.candidateAnswer ? "- Qualified" : "- Unqualified"}</Container>
                                )}
                                {e.type === "R" && (
                                    <Container sx={{ color: `${e?.answers[e?.candidateAnswer - 1] >= e?.marks ? "green" : "red"}` }}>
                                        {i + 1}{") "}Q.{e.question}  - Marks Obtained {"=>"} {e?.answers[e?.candidateAnswer - 1]} {e?.answers[e?.candidateAnswer - 1] >= e?.marks ? "- Qualified" : "- Unqualified"}</Container>
                                )}
                                {e.type === "C" && (
                                    <Container sx={{ color: `${calculateTotalMarks(e) >= e?.marks ? "green" : "red"}` }}>
                                        {i + 1}{") "}Q.{e.question}  - Marks Obtained {"=>"} {calculateTotalMarks(e)} {calculateTotalMarks(e) >= e?.marks ? "- Qualified" : "- Unqualified"}</Container>
                                )}
                                {e.type === "T" && (
                                    <Container
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            alignItems: "center",
                                            backgroundColor: "white"
                                        }}
                                    >
                                        {/* {e.candidateAnswer} */}
                                    </Container>
                                )}
                                {e.type === "R" && (
                                    <Container
                                        sx={{
                                            display: "flex",
                                            gap: "1rem"
                                        }}
                                    >
                                        {e?.options?.map((option, index) => (
                                            <FormControlLabel
                                                control={<Radio disabled />}
                                                label={option}
                                                /* eslint-disable eqeqeq */
                                                checked={index + 1 === parseInt(e?.candidateAnswer, 10)}
                                                // checked={e?.candidateAnswer?.map(e => +e - 1).includes(index)}
                                                // checked={e.candidateAnswer?.map(e => +e - 1).includes(index)}
                                                key={index}
                                                color={(e?.answers?.map(e => +e - 1).includes(index) || +e?.answer - 1 === index) && "red"}
                                            />
                                        ))}
                                    </Container>
                                )}
                                {e?.type === "C" && (
                                    <Container
                                        sx={{
                                            display: "flex",
                                            gap: "1rem"
                                        }}
                                    >
                                        {e?.options?.map((option, index) => (
                                            <FormControlLabel
                                                control={<Checkbox disabled />}
                                                label={option}
                                                checked={e?.candidateAnswer?.map(e => +e - 1).includes(index)}
                                                key={index}
                                                color={(e?.answers.map(e => +e - 1).includes(index) || +e?.answer - 1 === index) && "red"}
                                            />
                                        ))}
                                    </Container>
                                )}
                            </Container>
                        ))
                    ) : (
                        <Container sx={{ textAlign: "center", color: "red", marginTop: "10px" }}>
                            Assessment data not available
                        </Container>
                    )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Close</Button>
                    {/* <Button onClick={() => {handleSubmit(); handleClose()}} variant="outlined">Add</Button> */}
                </DialogActions>
            </Dialog>
    )
}

export default AssesmentModal
