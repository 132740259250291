// instruments.js (or any other suitable name)
export const AssesmentCategory = [
    {
        assesmentCategory: [
        'Pre-Screening',
        'General Assessment',
        "Recruiter's Assessment",
        "Interviewer's Assessment",
        'Behavioural Assessment',
      ],
    },
  ].map(({ label, assesmentCategory }) => ({
    options: assesmentCategory.map((s) => ({ name: s, label: s })),
  }));
  
  
  