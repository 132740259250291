import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import { showToast } from '../../utils/toast';
import {
  useEmailSettingSmtpDataMutation,
  useUpdateEmailSettingSmtpDataMutation,
} from '../../redux/services/candidate/CandidateServices';

const EmailCommunicationModal = ({ open, onClose, editData, refetch }) => {
  const [senderMail, setSenderMail] = useState('');
  const [authPassword, setAuthPassword] = useState('');
  const [emailBackend, setEmailBackend] = useState('');
  const [emailHost, setEmailHost] = useState('');
  const [emailPort, setEmailPort] = useState('');
  const [sendEmailSettingSmtpData] = useEmailSettingSmtpDataMutation();
  const [updateEmailSettingSmtpData] = useUpdateEmailSettingSmtpDataMutation();

  useEffect(() => {
    if (editData) {
      setSenderMail(editData.sender_mail);
      setAuthPassword(editData.auth_password);
      setEmailBackend(editData.email_backend);
      setEmailHost(editData.email_host);
      setEmailPort(editData.email_port);
    } else {
      setSenderMail('');
      setAuthPassword('');
      setEmailBackend('');
      setEmailHost('');
      setEmailPort('');
    }
  }, [editData]);

  const handleSendEmail = async () => {
    try {
      if (editData) {
        await updateEmailSettingSmtpData({
          id: editData.id,
          sender_mail: senderMail,
          auth_password: authPassword,
          email_backend: emailBackend,
          email_host: emailHost,
          email_port: emailPort,
        }).unwrap();
        showToast("success", "Email settings updated successfully!");
      } else {
        await sendEmailSettingSmtpData({
          sender_mail: senderMail,
          auth_password: authPassword,
          email_backend: emailBackend,
          email_host: emailHost,
          email_port: emailPort,
        }).unwrap();
        showToast("success", "Email settings saved successfully!");
      }
      refetch(); 
      onClose();
    } catch (error) {
      console.error('Failed to send email settings:', error);
      showToast("error", "Failed to save email settings.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{ width: '600px', margin: 'auto' }}>
      <DialogTitle>Email SMTP</DialogTitle>
      <DialogContent style={{ height: '400px' }}>
        <TextField
          autoFocus
          margin="dense"
          id="sender_mail"
          label="Sender Email"
          type="email"
          fullWidth
          variant="standard"
          value={senderMail}
          onChange={(e) => setSenderMail(e.target.value)}
        />
        <TextField
          margin="dense"
          id="auth_password"
          label="Auth Password"
          type="password"
          fullWidth
          variant="standard"
          value={authPassword}
          onChange={(e) => setAuthPassword(e.target.value)}
        />
        <TextField
          margin="dense"
          id="email_backend"
          label="Email Backend"
          type="text"
          fullWidth
          variant="standard"
          value={emailBackend}
          onChange={(e) => setEmailBackend(e.target.value)}
        />
        <TextField
          margin="dense"
          id="email_host"
          label="Email Host"
          type="text"
          fullWidth
          variant="standard"
          value={emailHost}
          onChange={(e) => setEmailHost(e.target.value)}
        />
        <TextField
          margin="dense"
          id="email_port"
          label="Email Port"
          type="number"
          fullWidth
          variant="standard"
          value={emailPort}
          onChange={(e) => setEmailPort(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSendEmail}>{editData ? 'Update' : 'Send'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailCommunicationModal;