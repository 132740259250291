import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getJobList: build.query({
      query: () => ({
        url: `${apiUrl.jobs}all-jobs`,
      }),
    }),
    getJobDetails: build.query({
      query: (id) => ({
        url: `${apiUrl.jobs}job-details/${id}`,
      }),
    }),
    getJobCandidates: build.query({
      query: (id) => ({
        url: `${apiUrl.jobs}job-candidates/${id}`
      })
    }),
    getJobCandidate: build.query({
      query: ({id,stage}) => ({
        url: `${apiUrl.jobs}job-candidates/${id}/${stage}/`
      })
    }),
    filterJob: build.mutation({
      query: (queryString) => ({
        url: `${apiUrl.jobs}all-jobs?${queryString}`,
        method: 'GET',
      }),
    }),
    getDraftJobList: build.query({
      query: () => ({
        url: `${apiUrl.jobs}all-draft-jobs/`,
      }),
    }),
    getDraftJobDetails: build.query({
      query: (id) => ({
        url: `${apiUrl.jobs}all-draft-jobs/${id}/`,
      }),
    }),
    publishDraftJob: build.mutation({
      query: (id) => ({
        url: `${apiUrl.jobs}all-draft-jobs/${id}/`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetJobListQuery,
  useGetJobDetailsQuery,
  useGetJobCandidatesQuery,
  useGetJobCandidateQuery,
  useFilterJobMutation,
  useGetDraftJobListQuery,
  useGetDraftJobDetailsQuery,
  usePublishDraftJobMutation

} = extendedApi;
