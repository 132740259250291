import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, IconButton, Button, Box } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const ShareJobModal = ({ open, onClose, jobId, jobTitle }) => {
    const [copied, setCopied] = useState(false);
    // const jobUrl = `http://localhost:3001/jobs/Careers/${jobId}/${encodeURIComponent(jobTitle)}`;
    // const jobUrl = `https://staging-career.edjobster.com/jobs/Careers/${jobId}/${encodeURIComponent(jobTitle)}`;
    const jobUrl = `https://jobs.edjobster.com/jobs/Careers/${jobId}/${encodeURIComponent(jobTitle)}`;

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    };

    return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { minHeight: '200px' , minWidth: '700px' } }}>
        <DialogTitle>Share this Job</DialogTitle>
        <DialogContent>
            <TextField
                fullWidth
                value={jobUrl}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <CopyToClipboard text={jobUrl} onCopy={handleCopy}>
                        <IconButton>
                        {copied ? <CheckIcon /> : <ContentCopyIcon />}
                        </IconButton>
                    </CopyToClipboard>
                    ),
                }}
            />
        <Box display="flex" justifyContent="center" mt={2}>
            <Button
                variant="contained"
                color="primary"
                onClick={onClose}
            >
                Done
            </Button>
        </Box>
        </DialogContent>
    </Dialog>
  );
};

export default ShareJobModal;