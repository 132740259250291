import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({ 
    endpoints: (build) => ({ 
        getBillingPlan: build.query({
            query: () => ({
              url: `${apiUrl.billingPlan}`,
              headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
              }
            }),
        }),
        getCurrentSubscription: build.query({
            query: () => ({
              url: `${apiUrl.currentSubscription}`,
              headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
              }
            }),
        }),
        createOrder: build.mutation({
            query: (planId) => ({
              url: `${apiUrl.createOrder}`,
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`,
                'Content-Type': 'application/json'
              },
              body: { plan_id: planId }
            }),
        }),
        paymentSuccess: build.mutation({
            query: (paymentData) => ({
              url: `${apiUrl.paymentSuccess}`,
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`,
                'Content-Type': 'application/json'
              },
              body: paymentData
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetBillingPlanQuery,
    useGetCurrentSubscriptionQuery,
    useCreateOrderMutation,
    usePaymentSuccessMutation,
} = extendedApi;