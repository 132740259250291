import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';



const extendedApi = apiBasePath.injectEndpoints({
    endpoints: (build) => ({
        // getCompanyInfo: build.query({
        //     query: () => ({
        //         url: `${apiUrl.companyInfo}`
        //     }),
        // }),
        // updateCompanyInfo: build.mutation({
        //     query: (data) => ({
        //         url: `${apiUrl.companyInfo}`,
        //         method: "POST",
        //         body: data,
        //     })
        // }),
        // updateCompanyLogo: build.mutation({
        //     query: (data) => ({
        //         url: `${apiUrl.companyLogo}`,
        //         method: "POST",
        //         body: data,
        //     })
        // }),
        // getCompanyTags: build.query({
        //     query: () => ({
        //         url: apiUrl.companyTags
        //     })
        // })
        getAllContact: build.query({
            query: () => ({
                url: `${apiUrl.contacts}`
            })
        }),
        updateContact: build.mutation({
            query: (data) => ({
                url: `${apiUrl.contacts}`,
                method: "POST",
                body: data,
            })
        }),
        deleteContact: build.mutation({
            query: (id) => ({
                url: `${apiUrl.contacts}?id=${id}`,
                method: "DELETE",
            })
        }),
        createContact: build.mutation({
            query: (data) => ({
                url: `${apiUrl.contacts}`,
                method: "POST",
                body: data,
            })
        }),
    }),
    overrideExisting: false,

});

export const { 
    useCreateContactMutation,
    useDeleteContactMutation,
    useUpdateContactMutation,
    useGetAllContactQuery,
 } = extendedApi;
