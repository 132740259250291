import React, { useEffect, useState } from 'react';

import {
  CardContent,
  Card,
  Grid,
  ListItemIcon,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  DialogContentText,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { AutoSizer } from 'react-virtualized';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import Iconify from '../Iconify';
import {
  useGetCandidateNotesListQuery,
  useGetNotesTypesQuery,
  useAddCandidateNotesMutation,
  useDeleteCandidateNotesMutation,
  useUpdateCandidateNotesMutation
} from '../../redux/services/notes/NotesServices';
import { showToast } from '../../utils/toast';

const useStyles = makeStyles({
  oddRow: {
    backgroundColor: '#f9f9f9',
  },
  evenRow: {
    backgroundColor: '#ffffff',
  },
});

const Notes = (props) => {
  const classes = useStyles();
  const { data: candidateNotesData, refetch } = useGetCandidateNotesListQuery(props.candidateId);
  const { data: candidateNoteType } = useGetNotesTypesQuery();
  const [addCandidateNotes, addCandidateNotesInfo] = useAddCandidateNotesMutation();
  const [updateCandidateNotes, updateCandidateNotesInfo] = useUpdateCandidateNotesMutation();
  const [deleteCandidateNote, deleteCandidateNoteinfo] = useDeleteCandidateNotesMutation();
  const [noteText, setNoteText] = useState('');
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editingNoteText, setEditingNoteText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState(null);

  const [selectedNoteType, setSelectedNoteType] = useState('');
  const [addToJobOpening, setAddToJobOpening] = useState(false);
  // const [value, setValue] = useState([]);

  useEffect(() => {
    refetch();
  },[])

  const handleChange = (e) => {
    setSelectedNoteType(e.target.value);
  };

  const onEditHandler = (id, note) => {
    setEditingNoteId(id);
    setEditingNoteText(note);
    setIsEditMode(true);
    setOpenModal(true);
  };

  const saveEditHandler = async () => {
    const result = await updateCandidateNotes({
      id: editingNoteId,
      note: editingNoteText,
    });
    if (result.error) {
      showToast('error', result.error.data.msg || 'Failed to update note');
    } else {
      showToast('success', 'Note updated successfully');
      setEditingNoteId(null);
      setEditingNoteText('');
      setOpenModal(false);
    }
    refetch();
  };

  const handleDeleteDialogOpen = (id) => {
    setNoteIdToDelete(id);
    setDeleteDialogOpen(true);
  };
  
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setNoteIdToDelete(null);
  };
  
  const handleDeleteConfirm = async () => {
    if (noteIdToDelete !== null) {
      try {
        await deleteCandidateNote(noteIdToDelete);
        showToast("success", "Note deleted successfully!");
        refetch();
      } catch (error) {
        showToast("error", "Failed to delete note.");
      }
      handleDeleteDialogClose();
    }
  };

  const notesChange = (e) => {
    setNoteText(e.target.value);
  };

  const addNotesHandler = async () => {
    await addCandidateNotes({
      candidate: props.candidateId,
      type: selectedNoteType,
      note: noteText,
      addToJobOpening,
    });
    setNoteText('')
    setOpenModal(false);
  };

  useEffect(() => {
    if (addCandidateNotesInfo.isSuccess) {
      showToast('success', 'Notes Saved Successfully');
      refetch();
    }
    if (addCandidateNotesInfo.isError) {
      showToast('error', addCandidateNotesInfo.error?.data?.msg || 'An error occurred');
    }
    if (deleteCandidateNoteinfo.isSuccess) {
      showToast('success', deleteCandidateNoteinfo.data.msg);
      refetch();
      setNoteText('');
    }
    if (deleteCandidateNoteinfo.isError) {
      showToast('error', deleteCandidateNoteinfo.error?.data?.msg || 'An error occurred');
      refetch();
    }
  }, [addCandidateNotesInfo, deleteCandidateNoteinfo]);

  const columns = [
    { field: 'note', headerName: 'Note', width: 700 },
    { field: 'created', headerName: 'Created', width: 450, valueFormatter: ({ value }) => formatDateRange(value) },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <>
          <Button variant="contained" onClick={() => onEditHandler(params.row.id, params.row.note)} sx={{ mr: 1 }}>
            Edit
          </Button>
          <Button variant="contained" onClick={() => handleDeleteDialogOpen(params.row.id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  function formatDateRange(createdDateTimeString) {
    const createdDateTime = new Date(createdDateTimeString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = createdDateTime.toLocaleDateString("en-US", options);
    const timeOptions = { hour: "numeric", minute: "numeric", second: "numeric"};
    const formattedTime = createdDateTime.toLocaleTimeString("en-US", timeOptions);
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime
  }

  return (
    <>
      <Grid container style={{ display: 'flex', alignItems: 'center' }}>
        <Grid  item md={10} sx={{ mt: 1, mb: 2 }}>
            <h3 className=''>Notes</h3>
        </Grid>
        <Grid item md={2} style={{ textAlign: 'center' }}>
            <Button
             onClick={() => { setIsEditMode(false); setOpenModal(true); }}
            variant="contained"
            style={{ textTransform: 'capitalize' }}
            >
            Add Note
            </Button>
        </Grid>
      </Grid>
      <Divider />
      
      <Grid container sx={{ mt: 4 }}>
        <Grid item md={12}>
        <div style={{ height: 300, width: '100%' }}>
            <AutoSizer>
              {({ height, width }) => (
                <DataGrid
                  rows={candidateNotesData?.notes || []}
                  columns={columns}
                  pageSize={3}
                  rowsPerPageOptions={[3]}
                  disableSelectionOnClick
                  autoHeight
                  style={{ height, width }}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? classes.evenRow : classes.oddRow
                  }
                />
              )}
            </AutoSizer>
          </div>
        </Grid>
      </Grid>

      <Dialog open={openModal} onClose={() => setOpenModal(false)} sx={{ '& .MuiDialog-paper': { minHeight: '300px', minWidth: '500px' } }}>
        <DialogTitle>{isEditMode ? 'Edit Note' : 'Add Note'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {isEditMode ? 'Edit your note below:' : 'Add a new note below:'}
            </DialogContentText>
            <TextField
              id="outlined-basic"
              label="Type Something"
              placeholder="Type Something..."
              fullWidth
              variant="outlined"
              size="small"
              sx={{ mt: 2 }} 
              value={isEditMode ? editingNoteText : noteText}
              onChange={(e) => isEditMode ? setEditingNoteText(e.target.value) : setNoteText(e.target.value)}
            />
             {!isEditMode && (
          <FormControlLabel
            control={
              <Checkbox
                checked={addToJobOpening}
                onChange={(e) => setAddToJobOpening(e.target.checked)}
                name="addToJobOpening"
                color="primary"
              />
            }
            label="Add this note to the job opening record"
            sx={{ mt: 2 }}
          />
        )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={isEditMode ? saveEditHandler : addNotesHandler}>
            {isEditMode ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }} // Adjust the height as needed
      >
        <DialogTitle>Delete Note</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Notes;
