import React from "react";
import HiringStatus from "../../components/individualCandidate/hiringStatus";

const Candidates = () => {
  return (
    <div>
      <HiringStatus />
    </div>
  );
};

export default Candidates;
