/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Button, Container, TextField, Switch, FormControlLabel, MenuItem, ListItemIcon } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Iconify from '../Iconify';

export default function EditableSection(props) {
  const {
    children,
    value,
    handleChange,
    multiLine,
    placeholder,
    fullWidth,
    name,
    deletable,
    handleDelete,
    currentType,
    types,
    handleChangeType,
    elementRequired,
    handleChangeRequired,
  } = props;
  const [editable, setEditable] = useState(false);
  return editable ? (
    <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2rem' }}>
      <Container sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <TextField
          variant="outlined"
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          fullWidth={fullWidth}
          multiline={multiLine}
          label="Field"
        />
        <TextField
          variant="outlined"
          name="type"
          value={currentType}
          onChange={handleChangeType}
          placeholder={currentType}
          fullWidth={fullWidth}
          multiline={multiLine}
          select
          SelectProps={{
            native: true,
          }}
          label="Type"
        >
          {types.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </TextField>
        <FormControlLabel
          name="required"
          value={elementRequired}
          control={<Switch defaultChecked={elementRequired} />}
          label="Required"
          onChange={handleChangeRequired}
          labelPlacement="start"
        />
      </Container>
      <Container sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Button variant="contained" onClick={() => setEditable(false)}>
          Save
        </Button>
      </Container>
    </Container>
  ) : (
    <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Container sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-start' }}>{children}</Container>
      <Container sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1rem' }}>
        {/* <Button variant="outlined" onClick={() => setEditable(true)}>
                        Edit
                    </Button> */}

        <Button
          style={{ minWidth: 0 }}
          onClick={() => setEditable(true)}
        >
          <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
            <Iconify icon="ep:edit" width={24} height={24} color={'blue'} />
          </ListItemIcon>
        </Button>
        {deletable && (
          // <Button variant="contained" color="error" onClick={handleDelete}>
          //     Delete
          // </Button>

          <LoadingButton
            style={{ minWidth: 0, margin: '0px 5px' }}
            // variant="contained"
            color="error"
            onClick={handleDelete}
            // onClick={() => onDeletAssesmenteHandler(dataIndex)}
          >
            <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} color={'red'} />
            </ListItemIcon>
          </LoadingButton>
        )}
      </Container>
    </Container>
  );
}
