import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Container } from '@mui/material';
import { useCreateOrderMutation, useGetCurrentSubscriptionQuery, useGetBillingPlanQuery, usePaymentSuccessMutation } from '../../../redux/services/settings/BillingService';

const Billing = () => {
  const navigate = useNavigate();
  const { data: billingPlanListData, error, isLoading, refetch: refetchBillingPlans } = useGetBillingPlanQuery(); 
  const { data: subscriptionData, error: subscriptionError, isLoading: subscriptionLoading, refetch: refetchSubscription } = useGetCurrentSubscriptionQuery();
  const [plans, setPlans] = useState([]);
  const [createOrder] = useCreateOrderMutation();
  const [paymentSuccess] = usePaymentSuccessMutation();
  const [currentSubscription, setCurrentSubscription] = useState(null);

  function createData(feature, basic, advance, premium) {
    return { feature, basic, advance, premium };
  }
  const rows = [
    createData('Feature 1', <DoneIcon />, <DoneIcon />, <DoneIcon />),
    createData('Feature 2', <CloseIcon />, <DoneIcon />, <DoneIcon />),
  ];

  useEffect(() => {
    refetchBillingPlans();
  },[])

  useEffect(() => {
    if (billingPlanListData && billingPlanListData.code === 200) {
      setPlans(billingPlanListData.data);
    }
  }, [billingPlanListData]);

  useEffect(() => {
    if (subscriptionData) {
      setCurrentSubscription(subscriptionData);
    }
  }, [subscriptionData]);

  const handlePayment = async (planId) => {
    try {
      const response = await createOrder(planId).unwrap();
      const options = {
        key: response.razorpay_key_id,
        amount: response.payment.amount * 100,
        currency : 'INR',
        name: 'Your Company Name',
        description: 'Test Transaction',
        order_id: response.payment.razorpay_order_id,
        handler: async (response) => {
          try {
            await paymentSuccess({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              plan_id: planId
            }).unwrap();
            alert('Payment successful');
            refetchBillingPlans();
            refetchSubscription();
          }catch (error){
            alert('Payment verification failed');
          }
        },
        theme: {
          color: '#F37254',
        },
      };
      if (window.Razorpay) {
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      } else {
        console.error('Razorpay SDK not loaded');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const navigateCancel = () => {
    navigate("/dashboard/InstituteSettings/settings")
  }

  return (
    <>
      
        <Box sx={{ flexGrow: 1 }}>
        <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="backbutton tt-back" style={{ display: 'flex' }}>
              <ArrowBackIosIcon
                onClick={navigateCancel}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </div>
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Billing
            </Typography>
        </Container>
          <Card style={{ padding: 40 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} style={{ marginBottom: 40 }} textAlign="center">
                <Typography variant="h6" gutterBottom>
                  Your Current Plan
                </Typography>
              </Grid>
              <Divider style={{ width: '100%' }} />
              {currentSubscription ? (
              <Card sx={{ minWidth: 275, mt: 2 }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: 40 }} gutterBottom>
                    ${currentSubscription.offer_price}/Month
                  </Typography>
                  <Typography variant="h5">{currentSubscription.plan.name}</Typography>
                  <Typography sx={{ mb: 2.5 }} color="text.secondary">
                    {currentSubscription.plan.features.split('\n').map((feature, index) => (
                      <React.Fragment key={index}>
                        {feature}
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <Button size="small" variant="contained" disabled>
                      CURRENT PLAN
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            ) : (
              <Typography variant="body1" color="textSecondary">
                No active subscription found.
              </Typography>
            )}
            </Grid>
            <Divider style={{ width: '100%', marginTop: 40, marginBottom: 20 }} />
            <Grid container spacing={2} justifyContent="space-evenly">
              <Grid item xs={12} style={{ marginBottom: 20 }} textAlign="center">
                <Typography sx={{ fontSize: 30 }} gutterBottom>
                  Select Plan
                </Typography>
              </Grid>
              <Divider style={{ width: '100%' }} />
              {plans.map(plan => (
                <Card key={plan.id} sx={{ minWidth: 275, mt: 2 }}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontSize: 40 }} gutterBottom>
                      {plan.offer ? (
                          <>
                            <span style={{ textDecoration: 'line-through', color: 'grey', marginRight: '10px', fontSize: '24px' }}>
                              ${plan.old_price}
                            </span>
                            <span style={{ marginRight: '10px', fontSize: '24px' }}>
                              ${plan.price}/Month
                            </span>
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {plan.offer}% off
                            </Typography>
                          </>
                        ) : (
                          <>${plan.price}</>
                      )}
                    </Typography>
                    <Typography variant="h5">{plan.name}</Typography>
                    <Typography sx={{ mb: 2.5 }} color="text.secondary">
                      {plan.features.split('\n').map((feature, index) => (
                        <React.Fragment key={index}>
                          {feature}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                    <CardActions sx={{ justifyContent: 'center' }}>
                      <Button
                        style={{ backgroundColor: '#FF4E50', background: 'linear-gradient(to right, #F9D423, #FF4E50)' }}
                        size="small"
                        variant="contained"
                        onClick={() => handlePayment(plan.id)}
                      >
                        UPGRADE
                      </Button>
                    </CardActions>
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </Card>
        </Box>
    </>
  );
};

export default Billing;
