import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "170px",
        height: '170px',
        overflow: "hidden",
        position: "relative",
        border: `3px dashed ${theme.palette.grey[500_80]}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));

const ImageDisplay = ({ logo }) => {
    const classes = useStyles();
    const [preview, setPreview] = useState();

    useEffect(() => {
        if (logo) {
            setPreview(logo);
        }
    }, [logo]);

    return (
        <Box className={classes.root} style={{ backgroundImage: `url(${preview})`, backgroundSize: "100% 100%" }}>
            {/* Image is displayed as a background */}
        </Box>
    );
}

export default ImageDisplay;