import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
    endpoints: (build) => ({
        getTaskOptionsListJobs: build.query({
            query: () => ({
            url: `${apiUrl.taskOptionsListJob}`,
            }),
        }),
        addTasksJobs: build.mutation({
            query: (data) => ({
            url: `${apiUrl.addTasksJob}`,
            method: 'POST',
            body: data,
            }),
        }),
        getTaskListJobs: build.query({
            query: (id) => ({
            url: `${apiUrl.addTasksJob}${id}/`,
            }),
        }),
        updateTasksJobs: build.mutation({
            query: ({ id, data }) => ({
            url: `${apiUrl.addTasksJob}${id}/`,
            method: 'PUT',
            body: data,
            }),
        }),
        deleteTasksJobs: build.mutation({
            query: (id) => ({
            url: `${apiUrl.addTasksJob}${id}/`,
            method: 'DELETE',
            }),
        }),
        completeTasksJobs: build.mutation({
            query: (id) => ({
            url: `${apiUrl.addTasksJob}${id}/complete/`,
            method: 'POST',
            }),
        }),
        getEventOptionsListJobs: build.query({
            query: () => ({
            url: `${apiUrl.eventOptionsListJob}`,
            }),
        }),
        addEventsJobs: build.mutation({
            query: (data) => ({
            url: `${apiUrl.eventsJob}`,
            method: 'POST',
            body: data,
            }),
        }),
        getEventListJobs: build.query({
            query: (id) => ({
            url: `${apiUrl.eventsJob}${id}/`,
            }),
        }),
        updateEventsJobs: build.mutation({
            query: ({ id, data }) => ({
            url: `${apiUrl.eventsJob}${id}/`,
            method: 'PUT',
            body: data,
            }),
        }),
        deleteEventsJobs: build.mutation({
            query: (id) => ({
            url: `${apiUrl.eventsJob}${id}/`,
            method: 'DELETE',
            }),
        }),
        getCallOptionsListJobs: build.query({
            query: () => ({
            url: `${apiUrl.callOptionsListJob}`,
            }),
        }),
        addCallsJobs: build.mutation({
            query: (data) => ({
            url: `${apiUrl.callsJob}`,
            method: 'POST',
            body: data,
            }),
        }),
        getCallListJobs: build.query({
            query: (id) => ({
            url: `${apiUrl.callsJob}${id}/`,
            }),
        }),
        updateCallsJobs: build.mutation({
            query: ({ id, data }) => ({
            url: `${apiUrl.callsJob}${id}/`,
            method: 'PUT',
            body: data,
            }),
        }),
        deleteCallsJobs: build.mutation({
            query: (id) => ({
            url: `${apiUrl.callsJob}${id}/`,
            method: 'DELETE',
            }),
        }),
        }),
    overrideExisting: false,
  });
  
  export const {
    useGetTaskOptionsListJobsQuery,
    useAddTasksJobsMutation,
    useGetTaskListJobsQuery,
    useUpdateTasksJobsMutation,
    useDeleteTasksJobsMutation,
    useCompleteTasksJobsMutation,
    useGetEventOptionsListJobsQuery,
    useAddEventsJobsMutation,
    useGetEventListJobsQuery,
    useUpdateEventsJobsMutation,
    useDeleteEventsJobsMutation,
    useGetCallOptionsListJobsQuery,
    useAddCallsJobsMutation,
    useGetCallListJobsQuery,
    useUpdateCallsJobsMutation,
    useDeleteCallsJobsMutation,
  } = extendedApi;
  